import { ReactComponent as Hole1 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_1.svg";
import { ReactComponent as Hole2 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_2.svg";
import { ReactComponent as Hole3 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_3.svg";
import { ReactComponent as Hole4 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_4.svg";
import { ReactComponent as Hole5 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_5.svg";
import { ReactComponent as Hole6 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_6.svg";
import { ReactComponent as Hole7 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_7.svg";
import { ReactComponent as Hole8 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_8.svg";
import { ReactComponent as Hole9 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_9.svg";
import { ReactComponent as Hole10 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_10.svg";
import { ReactComponent as Hole11 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_11.svg";
import { ReactComponent as Hole12 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_12.svg";
import { ReactComponent as Hole13 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_13.svg";
import { ReactComponent as Hole14 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_14.svg";
import { ReactComponent as Hole15 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_15.svg";
import { ReactComponent as Hole16 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_16.svg";
import { ReactComponent as Hole17 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_17.svg";
import { ReactComponent as Hole18 } from "./../../../assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_18.svg";

const holeImages = {
  1: <Hole1 width="100%" />,
  2: <Hole2 width="100%" />,
  3: <Hole3 width="100%" />,
  4: <Hole4 width="100%" />,
  5: <Hole5 width="100%" />,
  6: <Hole6 width="100%" />,
  7: <Hole7 width="100%" />,
  8: <Hole8 width="100%" />,
  9: <Hole9 width="100%" />,
  10: <Hole10 width="100%" />,
  11: <Hole11 width="100%" />,
  12: <Hole12 width="100%" />,
  13: <Hole13 width="100%" />,
  14: <Hole14 width="100%" />,
  15: <Hole15 width="100%" />,
  16: <Hole16 width="100%" />,
  17: <Hole17 width="100%" />,
  18: <Hole18 width="100%" />,
};

export default holeImages;

const ads = [
  {
    id: "kaerly",
    title: "Kaerly",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/kaerly_desktop.gif"
        alt="Kaerly"
        title="Kaerly"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/kaerly_mobile.gif"
        alt="Kaerly"
        title="Kaerly"
      />
    ),
    link: "https://www.kaerly.com",
    time: 13000,
  },
  {
    id: "hilton",
    title: "Hilton",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/hilton_desktop.gif"
        alt="Hilton"
        title="Hilton"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/hilton_mobile.gif"
        alt="Hilton"
        title="Hilton"
      />
    ),
    link: "https://www.hilton.com/en/",
    time: 7000,
  },
  {
    id: "commercetools",
    title: "Commercetools",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/commercetools_desktop.gif"
        alt="Commercetools"
        title="Commercetools"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/commercetools_mobile.gif"
        alt="Commercetools"
        title="Commercetools"
      />
    ),
    link: "https://www.commercetools.com",
    time: 7000,
  },
  {
    id: "bayern_tourismus",
    title: "Bayern Tourismus",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/bayern_tourismus_desktop.gif"
        alt="Bayern Tourismus"
        title="Bayern Tourismus"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/bayern_tourismus_mobile.gif"
        alt="Bayern Tourismus"
        title="Bayern Tourismus"
      />
    ),
    link: "https://www.bavaria.travel/",
    time: 7000,
  },
  {
    id: "fortinet",
    title: "Fortinet",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/fortinet_desktop.gif"
        alt="Fortinet"
        title="Fortinet"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/fortinet_mobile.gif"
        alt="Fortinet"
        title="Fortinet"
      />
    ),
    link: "https://www.fortinet.com/customers/dp-world-tour",
    time: 6000,
  },
  {
    id: "callaway",
    title: "Callaway",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/callaway_desktop.gif"
        alt="Callaway"
        title="Callaway"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/callaway_mobile.gif"
        alt="Callaway"
        title="Callaway"
      />
    ),
    link: "https://www.topgolfcallawaybrands.com",
    time: 8000,
  },
  {
    id: "securitas",
    title: "Securitas",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/securitas_desktop.gif"
        alt="Securitas"
        title="Securitas"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/securitas_mobile.gif"
        alt="Securitas"
        title="Securitas"
      />
    ),
    link: "https://www.securitas.com",
    time: 6000,
  },
  {
    id: "rolex",
    title: "Rolex",
    imgDesktop: (
      <iframe
        title="Rolex"
        src="https://static.rolex.com/clocks/2022/bmw_golfsport_desk_en_HTML_1250x140/rolex.html"
        style={{
          width: "1250px",
          height: "140px",
          border: "0",
          margin: "0",
          padding: "0",
          overflow: "hidden",
          //scroll: 'none'
        }}
        scrolling="no"
        frameBorder="no"
      />
    ),
    imgMobile: (
      <iframe
        title="Rolex"
        src="https://static.rolex.com/clocks/2022/bmw_golfsport_mobile_en_HTML_320x110/rolex.html"
        style={{
          width: "320px",
          height: "110px",
          border: "0",
          margin: "0",
          padding: "0",
          overflow: "hidden",
          //scroll: "none",
        }}
        scrolling="no"
        frameBorder="no"
      />
    ),
    link: "https://www.commercetools.com",
    time: 7000,
  },
  {
    id: "alcatel",
    title: "Alcatel Lucent",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/alcatel_desktop.gif"
        alt="Alcatel Lucent"
        title="Alcatel Lucent"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/alcatel_mobile.gif"
        alt="Alcatel Lucent"
        title="Alcatel Lucent"
      />
    ),
    link: "https://www.al-enterprise.com",
    time: 6000,
  },
  {
    id: "gerard_bertrand",
    title: "Gérard Bertrand",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/gerard_bertrand_desktop.jpg"
        alt="Gérard Bertrand"
        title="Gérard Bertrand"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/gerard_bertrand_mobile.jpg"
        alt="Gérard Bertrand"
        title="Gérard Bertrand"
      />
    ),
    link: "https://de.gerard-bertrand.com/",
    time: 6000,
  },
  {
    id: "konica_minolta",
    title: "Konica Minolta",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/konica_minolta_desktop.gif"
        alt="Konica Minolta"
        title="Konica Minolta"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/konica_minolta_mobile.gif"
        alt="Konica Minolta"
        title="Konica Minolta"
      />
    ),
    link: "https://www.konicaminolta.eu/eu-en",
    time: 7000,
  },
  // {
  //   id: "enterprise",
  //   title: "Enterprise",
  //   imgDesktop: (
  //     <img
  //       src="/assets/images/partner/2024/enterprise_desktop.gif"
  //       alt="Enterprise"
  //       title="Enterprise"
  //     />
  //   ),
  //   imgMobile: (
  //     <img
  //       src="/assets/images/partner/2024/enterprise_mobile.gif"
  //       alt="Enterprise"
  //       title="Enterprise"
  //     />
  //   ),
  //   link: "https://www.enterprise.de/en/home.html",
  //   time: 7000,
  // },
  {
    id: "stroeer",
    title: "Ströer",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/stroeer_desktop.gif"
        alt="Ströer"
        title="Ströer"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/stroeer_mobile.gif"
        alt="Ströer"
        title="Ströer"
      />
    ),
    link: "https://www.stroeer.de/en",
    time: 8000,
  },
  {
    id: "emirates",
    title: "Emirates",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/emirates_desktop.gif"
        alt="Emirates"
        title="Emirates"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/emirates_mobile.gif"
        alt="Emirates"
        title="Emirates"
      />
    ),
    link: "https://www.emirates.com",
    time: 17000,
  },
  {
    id: "antenne_bayern",
    title: "Antenne Bayern Group",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/antenne_bayern_desktop.gif"
        alt="Antenne Bayern Group"
        title="Antenne Bayern Group"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/antenne_bayern_mobile.gif"
        alt="Antenne Bayern Group"
        title="Antenne Bayern Group"
      />
    ),
    link: "https://www.antenne.group",
    time: 6000,
  },
  {
    id: "ntt_data",
    title: "NTT Data",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/ntt_data_desktop.gif"
        alt="NTT Data"
        title="NTT Data"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/ntt_data_mobile.gif"
        alt="NTT Data"
        title="NTT Data"
      />
    ),
    link: "https://www.nttdata.com/global/en/",
    time: 6000,
  },
  {
    id: "goodyear",
    title: "Goodyear",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/goodyear_desktop.gif"
        alt="Goodyear"
        title="Goodyear"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/goodyear_mobile.gif"
        alt="Goodyear"
        title="Goodyear"
      />
    ),
    link: "https://www.goodyear.com/corporate",
    time: 6000,
  },
  // {
  //   id: "voss",
  //   title: "VOSS",
  //   imgDesktop: (
  //     <img
  //       src="/assets/images/partner/2024/voss_desktop.gif"
  //       alt="VOSS"
  //       title="VOSS"
  //     />
  //   ),
  //   imgMobile: (
  //     <img
  //       src="/assets/images/partner/2024/voss_mobile.gif"
  //       alt="VOSS"
  //       title="VOSS"
  //     />
  //   ),
  //   link: "https://www.vosswater.com",
  //   time: 7000,
  // },
  {
    id: "flughafen_muenchen",
    title: "Flughafen München",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/flughafen_muenchen_desktop.gif"
        alt="Flughafen München"
        title="Flughafen München"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/flughafen_muenchen_mobile.gif"
        alt="Flughafen München"
        title="Flughafen München"
      />
    ),
    link: "https://www.munich-airport.com/",
    time: 9000,
  },
  {
    id: "taittinger",
    title: "Taittinger",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/taittinger_desktop.jpg"
        alt="Taittinger"
        title="Taittinger"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/taittinger_mobile.jpg"
        alt="Taittinger"
        title="Taittinger"
      />
    ),
    link: "https://www.taittinger.com",
    time: 6000,
  },
  {
    id: "rheinland_versicherungen",
    title: "Rheinland Versicherungen",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/rheinland_versicherungen_desktop.gif"
        alt="Rheinland Versicherungen"
        title="Rheinland Versicherungen"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/rheinland_versicherungen_mobile.gif"
        alt="Rheinland Versicherungen"
        title="Rheinland Versicherungen"
      />
    ),
    link: "https://www.rheinland-versicherungsgruppe.de/en/",
    time: 6000,
  },
  {
    id: "sueddeutsche_zeitung",
    title: "Süddeutsche Zeitung",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/sueddeutsche_zeitung_desktop.gif"
        alt="Süddeutsche Zeitung"
        title="Süddeutsche Zeitung"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/sueddeutsche_zeitung_mobile.gif"
        alt="Süddeutsche Zeitung"
        title="Süddeutsche Zeitung"
      />
    ),
    link: "https://www.sueddeutsche.de/",
    time: 8000,
  },
  {
    id: "benediktiner",
    title: "Benediktiner",
    imgDesktop: (
      <img
        src="/assets/images/partner/2024/benediktiner_desktop.gif"
        alt="Benediktiner"
        title="Benediktiner"
      />
    ),
    imgMobile: (
      <img
        src="/assets/images/partner/2024/benediktiner_mobile.gif"
        alt="Benediktiner"
        title="Benediktiner"
      />
    ),
    link: "https://www.benediktiner-weissbier.de/en/",
    time: 9000,
  },
  // {
  //   id: "sky",
  //   title: "Sky Extra",
  //   imgDesktop: (
  //     <img
  //       src="/assets/images/partner/2024/sky_desktop.gif"
  //       alt="Sky Extra"
  //       title="Sky Extra"
  //     />
  //   ),
  //   imgMobile: (
  //     <img
  //       src="/assets/images/partner/2024/sky_mobile.gif"
  //       alt="Sky Extra"
  //       title="Sky Extra"
  //     />
  //   ),
  //   link: "https://www.sky.de",
  //   time: 7000,
  // },
];

export default ads;

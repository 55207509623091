import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";

const BASE =
  process.env.NODE_ENV !== "production"
    ? "https://pahebmwi-dev.bmw-golfsport-live.workers.dev"
    : "";

function transformToObject(data: string) {
  let infoObj: {
    [key: string]: string;
  } = {};
  data.split("\n").map((e) => {
    let eMod = e.split("=");
    if (eMod.length > 1) {
      infoObj[eMod[0]] = eMod[1];
    }
    return e;
  });
  return infoObj;
}

export async function fetchCfTrace() {
  const { data }: AxiosResponse<string> = await axios.get(
    BASE + "/cdn-cgi/trace"
  );
  return transformToObject(data);
}

function useCfTrace() {
  return useQuery("cf-trace", fetchCfTrace);
}

export default useCfTrace;

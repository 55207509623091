import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "./Avatar";
import { ReactComponent as ArrowLeft } from "./../../assets/icons/icons-arrow-left.svg";
import useLeaderboard from "../../hooks/queries/useLeaderboard";
import { LeaderboardItem } from "../../types/leaderboard";
import LeaderboardSearch from "./LeaderboardSearch";
import useFavoritesStore from "../../hooks/store/useFavoritesStore";
import FavButton from "./FavButton";
import moment from "moment";

const Separator: React.FC<{ text: string; kind: "fav" | "all" }> = ({
  text,
  kind,
}) => {
  return (
    <div className="h-top-leaderboard-item w-5">
      <div
        className={`h-5 w-24 transform -rotate-90 ${
          kind === "fav" ? "bg-bluehover" : "bg-dark_grey"
        } flex justify-center items-center`}
        style={{
          marginTop: 27,
          marginLeft: -38,
        }}
      >
        <div
          className="font-bold text-white tracking-wider uppercase"
          style={{ fontSize: 8 }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

const PlayerItem: React.FC<{ player: LeaderboardItem; active: boolean }> = ({
  player,
  active,
}) => {
  return (
    <Link to={`/players/${player.playerId}`} className={`hover:bg-white_grey`}>
      <div
        className={`flex flex-row flex-1 w-24 md:w-72 px-3 py-1 md:py-1 md:px-4
          border-b-4 border-l-0.5 border-transparent
        `}
        style={{
          borderBottomColor: active ? "#1c69d4" : "transparent",
          borderLeftColor: "#e6e6e6",
          height: 75,
        }}
      >
        <div className="flex flex-col md:flex-row flex-1 justify-between md:justify-start">
          <div className="md:flex text-2xl font-light sm:hidden xs:hidden">
            {player.rank}
          </div>
          <div className="flex self-center ml-3 mr-4 relative">
            <div className="flex md:hidden absolute bg-white w-5 h-5 rounded-full z-10 -right-2 justify-center items-center text-xxs font-normal">
              {player.toPar}
            </div>
            <Avatar
              size={50}
              playerId={String(player.playerId)}
              playerName={`${player.firstName} ${player.lastName}`}
            />
          </div>
          <div className="flex flex-col self-center justify-center">
            <div className="text-tiny font-semibold truncate overflow-ellipsis w-16 md:w-28 md:text-sm leading-none md:font-bold text-black_grey tracking-wider xs:pt-1 sm:pt-1">
              <span className="md:hidden">{player.rank}.</span>{" "}
              {player.firstName[0]}. {player.lastName}
            </div>
            <div className="md:flex flex-row justify-between mt-1 flex-1 xs:hidden sm:hidden md:visible">
              <div className="text-sm font-bold text-blue mr-3">
                {player.toPar}
              </div>
              <div className="text-sm font-semibold text-dark_grey">
                {player.holesThrough === 0
                  ? moment(player.teeTime).format("HH:mm")
                  : `Thru ${player.holesThrough}`}
              </div>
            </div>
          </div>
          <div className="hidden md:flex flex-1 items-start justify-end">
            <FavButton playerId={player.playerId} size={22} />
          </div>
        </div>
      </div>
    </Link>
  );
};

const TopLeaderboard: React.FC<{}> = () => {
  const leaderboard = useLeaderboard();
  const { favs } = useFavoritesStore();
  const path = useLocation();

  let containerRef = useRef<HTMLDivElement | null>(null);
  let scrollRef = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  const playerFavsList = leaderboard.data
    ? leaderboard.data.filter((p) => favs.includes(p.playerId))
    : [];

  const checkOverflow = useCallback(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.scrollWidth);
      setHasOverflow(window.innerWidth < width);
      setCanScrollRight(scrollLeft < width - window.innerWidth);
      setCanScrollLeft(scrollLeft > 0);
    }
  }, [scrollLeft, width]);

  useEffect(() => {
    checkOverflow();
  }, [leaderboard, checkOverflow]);

  useEffect(() => {
    const innerRef = scrollRef.current;
    function setUpdatedScroll() {
      if (!innerRef) return false;
      setScrollLeft(innerRef.scrollLeft);
      checkOverflow();
    }
    if (hasOverflow && innerRef) {
      innerRef.addEventListener("scroll", setUpdatedScroll, { passive: true });
    }
    return () => innerRef?.removeEventListener("scroll", setUpdatedScroll);
  }, [checkOverflow, hasOverflow]);

  function scrollDirection(direction: "right" | "left") {
    if (scrollRef.current) {
      let newScollLeft =
        direction === "right"
          ? scrollRef.current.scrollLeft + 256 * 2
          : scrollRef.current.scrollLeft - 256 * 2;
      setScrollLeft(newScollLeft);
      scrollRef.current.scroll({
        left: newScollLeft,
        top: 0,
        behavior: "smooth",
      });
    }
  }

  if (!leaderboard.data) {
    return null;
  }

  return (
    <div
      ref={scrollRef}
      className="h-top-leaderboard-item bg-white overflow-y-hidden overflow-x-auto hide-scrollbar pr-12"
    >
      <div
        ref={containerRef}
        className="flex flex-row"
        style={{ width: "fit-content" }}
      >
        {hasOverflow ? (
          <div className=" w-12 h-top-leaderboard-item bg-white border-l-0.5 border-l-middle_grey items-center justify-center z-10 absolute right-0 flex-col hidden  md:flex">
            <div
              onClick={() => (canScrollRight ? scrollDirection("right") : null)}
              className={`${
                !canScrollRight ? "opacity-30" : "cursor-pointer"
              } flex flex-1 items-center justify-center text-lg text-dark_grey`}
            >
              <ArrowLeft className="transform rotate-180" />
            </div>
            <div
              onClick={() => (canScrollLeft ? scrollDirection("left") : null)}
              className={`${
                !canScrollLeft ? "opacity-30" : "cursor-pointer"
              } flex flex-1 items-center justify-center text-lg text-dark_grey`}
            >
              <ArrowLeft className="" />
            </div>
          </div>
        ) : null}
        <LeaderboardSearch />
        {playerFavsList.length > 0 ? (
          <Separator text="favourites" kind="fav" />
        ) : null}
        {playerFavsList.map((p) => {
          return (
            <PlayerItem
              key={`favs_${p.playerId}`}
              player={p}
              active={path.pathname.includes(String(p.playerId))}
            />
          );
        })}
        <Separator text="LEADERBOARD" kind="all" />
        {leaderboard.data.map((player, index) => (
          <PlayerItem
            key={player.playerId}
            player={player}
            active={path.pathname.includes(String(player.playerId))}
          />
        ))}
      </div>
    </div>
  );
};

export default TopLeaderboard;

import i18n from "../../../misc/i18n";
import { CourseHole } from "../../../types/course";

type ScoreBoxProps = {
  noDimensions?: boolean;
  score: number;
  hole: Pick<CourseHole, "holeNo" | "par">;
};

export function getScoreInfo(diff: number) {
  // eagle or better
  if (diff < -1) {
    return {
      bg: "bg-red rounded-full",
      size: "w-7 h-7",
      style: "bg-transparent rounded-full border border-red text-white",
      title: i18n.hole.scoring.eagleBetter,
    };
  }

  // birdie
  if (diff === -1) {
    return {
      bg: "bg-red rounded-full",
      size: "w-6 h-6",
      style: "text-white rounded-full border border-red bg-red",
      title: i18n.hole.scoring.birdie,
    };
  }
  // par
  if (diff === 0) {
    return {
      bg: "bg-transparent",
      size: "w-6 h-6",
      style: "",
      title: i18n.hole.par,
    };
  }
  // bogey
  if (diff === 1) {
    return {
      bg: "bg-blue",
      size: "w-6 h-6",
      style: "text-white border border-blue bg-blue",
      title: i18n.hole.scoring.bogey,
    };
  }
  // bogey or worse
  if (diff > 1) {
    return {
      bg: "bg-blue",
      size: "w-7 h-7",
      style: "bg-transparent border border-blue text-white",
      title: i18n.hole.scoring.double,
    };
  }
  return {
    bg: "",
    style: "",
    size: "",
    title: "NA",
  };
}

const ScoreBox: React.FC<ScoreBoxProps> = ({
  score,
  hole,
  noDimensions = false,
}) => {
  const diff = score - hole.par;

  // if score is 0, hole has not been played
  const scoreInfo =
    score !== 0
      ? getScoreInfo(diff)
      : { bg: "", style: "", title: "", size: "" };

  return (
    <div
      title={scoreInfo.title}
      className={`flex flex-1 p-0.25 items-center justify-center ${
        noDimensions ? "" : scoreInfo.size
      } ${scoreInfo.style}`}
      style={{ maxWidth: 24, maxHeight: 24 }}
    >
      <div
        className={`flex justify-center font-semibold items-center w-full h-full ${scoreInfo.bg}`}
      >
        {score === 0 ? "-" : score}
      </div>
    </div>
  );
};

export default ScoreBox;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { usePlayer } from "../../../hooks/queries/usePlayers";
import useIsMobile from "../../../hooks/useIsMobile";
import i18n from "../../../misc/i18n";
import { LeaderboardItem } from "../../../types/leaderboard";
import Scorecard from "../Player/Scorecard";
import ScorecardLegend from "../Player/ScorecardLegend";

import { ReactComponent as Arrow } from "./../../../assets/icons/icons-arrow.svg";
import ScorecardAllRounds from "./ScorecardAllRounds";

type RoundKeys = "r1" | "r2" | "r3" | "r4";

const LeaderboardScorecard: React.FC<{ player: LeaderboardItem }> = ({
  player,
}) => {
  const { data: playerData } = usePlayer(Number(player.playerId));
  const { isMobile, innerWidth } = useIsMobile();
  const [selectedScorecardKey, setSelectedScorecardKey] =
    useState<RoundKeys>(`r1`);

  useEffect(() => {
    if (playerData) {
      if (playerData.scorecard) {
        const latestScorecards = playerData
          ? Object.entries(playerData.scorecard)
          : [];
        if (latestScorecards[latestScorecards.length - 1]) {
          setSelectedScorecardKey(
            latestScorecards[latestScorecards.length - 1][0] as RoundKeys,
          );
        }
      }
    }
  }, [playerData]);

  function changeSelectedRound(key: RoundKeys) {
    setSelectedScorecardKey(key);
  }

  if (!playerData) {
    return null;
  }

  const scorecardsArray = Object.entries(playerData.scorecard);
  let latestScorecard =
    scorecardsArray.length > 0
      ? playerData.scorecard[selectedScorecardKey] ?? null
      : null;
  if (latestScorecard) {
    latestScorecard.sort((a, b) => a.hole - b.hole);
  }

  return (
    <div className="px-4">
      {isMobile || innerWidth < 1100 ? (
        <div className="flex flex-row py-5">
          {scorecardsArray.map(([id, _], index) => (
            <button
              type="button"
              onClick={() => changeSelectedRound(id as RoundKeys)}
              key={id}
              className={`
          tracking-wider font-normal
          text-xxs mr-5
          ${
            selectedScorecardKey === id ? "text-black_grey" : "text-middle_grey"
          }
          `}
            >
              Round {index + 1}
            </button>
          ))}
        </div>
      ) : null}
      <div className={`${isMobile || innerWidth < 1100 ? "" : "mt-12 px-4"}`}>
        {isMobile || innerWidth < 1100 ? (
          <Scorecard scorecard={latestScorecard} />
        ) : (
          <>
            <ScorecardAllRounds scorecards={scorecardsArray.map((t) => t[1])} />
          </>
        )}
      </div>
      <div className="pt-10 pb-4">
        <ScorecardLegend textClass="text-black_grey text-tiny" />
      </div>
      <div className="pt-4 px-2">
        <Link to={`/players/${player.playerId}`}>
          <div className="flex flex-row items-center">
            <div className="text-black_grey text-sm font-normal tracking-wide">
              {i18n.leaderboard.viewProfile}
            </div>
            <div className="pl-2">
              <Arrow className="text-black_grey" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default LeaderboardScorecard;

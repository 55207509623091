import { Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import useTicker from "../../../hooks/queries/useTicker";
import i18n from "../../../misc/i18n";
import { TickerItem as TickerItemType } from "../../../types/ticker";
import { ReactComponent as ArrowLeft } from "./../../../assets/icons/icons-arrow-left.svg";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import TickerItem from "./TickerItem";
import useIsMobile from "../../../hooks/useIsMobile";

type TickerProps = {};

const Ticker: React.FC<TickerProps> = () => {
  const [lastSequence, setLastSequence] = useState<null | number>(null);
  const [items, setItems] = useState<TickerItemType[]>([]);
  const [viewItems, setViewItems] = useState<TickerItemType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const { data, isLoading, refetch, isFetching, isError } =
    useTicker(lastSequence);
  const { isMobile: mobile } = useIsMobile();
  const [maxItems, setMaxItems] = useState(0);
  const [newItemLabel, setNewItemLabel] = useState(false);

  useEffect(() => {
    function checkMaxItems() {
      setMaxItems(mobile ? 100 : Math.floor((window.innerHeight - 240) / 144));
    }
    checkMaxItems();
    window.addEventListener("resize", checkMaxItems);
    return () => window.removeEventListener("resize", checkMaxItems);
  }, [mobile]);

  useEffect(() => {
    if (data && !isFetching) {
      if (data[0]) {
        if (data[0].sequence !== lastSequence) {
          setLastSequence(data[0].sequence);
          if (lastSequence !== null) {
            triggerNewItem();
            setOffset(0);
          }
        }
      }
    }
  }, [data, lastSequence, isFetching, refetch]);

  useEffect(() => {
    if (mobile) {
      setOffset(0);
    }
  }, [mobile]);

  useEffect(() => {
    if (data) {
      if (data.length > 0) {
        setItems((oldItems) => [...[...data, ...oldItems]]);
      }
    }
  }, [data]);

  useEffect(() => {
    setViewItems((_) => [...items.slice(offset, offset + maxItems)]);
  }, [offset, maxItems, items]);

  function triggerNewItem() {
    setNewItemLabel(true);
    setTimeout(() => {
      setNewItemLabel(false);
    }, 4000);
  }

  function handleLeft() {
    if (offset === 0 || offset - maxItems < 0) {
      setOffset(0);
    }
    setOffset((oldOffset) => oldOffset - maxItems);
  }

  function handleRight() {
    if (items.length <= offset + maxItems) {
      return false;
    }
    setOffset((oldOffset) => oldOffset + maxItems);
  }

  if (data == null && isLoading) {
    return <Loading />;
  }

  if (data == null && !isLoading && isError) {
    return <Error />;
  }

  const leftDisabled = offset === 0;
  const rightDisabled = items.length <= maxItems;

  return (
    <div
      className={`w-full
      ${window.innerWidth < 1400 ? "md:w-72" : "md:w-right-content-box"}
      md:bottom-6 md:top-52 md:absolute md:right-9 md:overflow-hidden`}
    >
      <div className="flex flex-1 justify-end h-8">
        <div className="hidden md:flex w-36 h-8 bg-white items-center">
          <div className="flex flex-1 flex-row items-center px-4">
            <div className="flex flex-1 text-black_grey font-bold text-tiny tracking-wider uppercase">
              Liveticker
            </div>
            <button
              type="button"
              disabled={leftDisabled}
              onClick={handleLeft}
              className={`flex w-8 items-center justify-center text-dark_grey hover:text-black_grey ${
                leftDisabled ? "opacity-50" : ""
              }`}
            >
              <ArrowLeft className="" />
            </button>
            <button
              type="button"
              disabled={rightDisabled}
              onClick={handleRight}
              className={`flex w-8 items-center justify-center text-dark_grey hover:text-black_grey ${
                rightDisabled ? "opacity-50" : ""
              }`}
            >
              <ArrowLeft className="transform rotate-180" />
            </button>
          </div>
        </div>
        <div className="flex md:hidden flex-1 flex-row justify-center items-center px-4 border-b-2 border-grey4">
          <div className="text-black_grey font-bold text-tiny tracking-wider uppercase">
            Liveticker
          </div>
        </div>
      </div>

      {newItemLabel ? (
        <div className="mt-2 md:mt-0 animate-pulse uppercase text-white font-bold text-tiny tracking-wider bg-blue absolute left-0 top-3 w-24 h-5 justify-center items-center flex">
          {i18n.ticker.newTicker}
        </div>
      ) : null}
      <div className="overflow-y-hidden">
        {data! && viewItems.length === 0 ? (
          <div className="flex flex-1 w-full h-full justify-center bg-white">
            <div className="py-11">
              <h3 className="font-semibold uppercase text-middle_grey">
                {i18n.ticker.soon}
              </h3>
            </div>
          </div>
        ) : null}
        {viewItems.map((t: TickerItemType, i: number) => (
          <Transition
            appear={true}
            show={true}
            enter={`transition-all duration-400 transform ease-in`}
            enterFrom="opacity-0 -translate-y-2"
            enterTo="opacity-100 translate-y-0"
            key={t.itemId}
            style={{
              transitionDelay: `${i * 50}ms`,
            }}
          >
            <div>
              <TickerItem item={t} />
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
};

export default Ticker;

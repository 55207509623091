import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import useLeaderboard from "../../../hooks/queries/useLeaderboard";
import useLayoutStore from "../../../hooks/store/useLayoutStore";
import { BASE_TITLE } from "../../../misc/config";
import { currentTournamentRound } from "../../../misc/helpers";
import i18n from "../../../misc/i18n";
import TopNavigation from "../../layout/TopNavigation";
import Error from "../../shared/Error";
import LeaderboardSearch from "../../shared/LeaderboardSearch";
import Loading from "../../shared/Loading";
import ScorecardLegend from "../Player/ScorecardLegend";
import LeaderboardList from "./LeaderboardList";

const LeaderboardFull: React.FC<{}> = () => {
  const { data, isLoading, isError } = useLeaderboard();
  const { setLogoFixed } = useLayoutStore();

  const projectedCut = data ? data[64] : null;
  const projectedCutScore = useMemo(
    () =>
      projectedCut
        ? data?.find(
            (p) =>
              (p.toPar === "E" ? 0 : Number(p.toPar)) >
              (projectedCut.toPar === "E" ? 0 : Number(projectedCut.toPar)),
          )
        : null,
    [data, projectedCut],
  );
  const cutDone = data?.find((p) => p.status === "Cut");

  useEffect(() => {
    setLogoFixed(false);
  }, [setLogoFixed]);

  const fullPageTitle = `${BASE_TITLE} Leaderboard`;

  return (
    <div>
      <Helmet>
        <title>{fullPageTitle}</title>
        <meta property="og:title" content={fullPageTitle} />
      </Helmet>
      {/* <div className="sticky top-0"> */}
      <LeaderboardSearch openMode />
      <TopNavigation />
      {/* </div> */}

      <div className="px-5 md:px-24">
        {!data && !isLoading && isError ? <Error /> : null}

        <h2 className="mt-32 md:mt-44 uppercase text-white text-2xl md:text-5xl font-light tracking-wider">
          Leaderboard
        </h2>
        <div className="hidden md:flex flex-1 my-4 justify-between">
          <ScorecardLegend />
          {projectedCutScore !== null &&
          !cutDone &&
          currentTournamentRound === 2 ? (
            <div className="self-end justify-end items-end flex">
              <div className="text-white text-sm text-right font-bold">
                {i18n.leaderboard.projectedCut}:{"  "}
                {projectedCutScore?.toPar}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {!isLoading && data ? (
        <div className="mt-11">
          <LeaderboardList
            players={data}
            isLoading={isLoading || !data}
            projectedCutScore={
              !cutDone && currentTournamentRound === 2
                ? projectedCutScore?.toPar
                : null
            }
          />
        </div>
      ) : (
        <div
          style={{
            minHeight: "calc(100vh - 347px)",
          }}
        >
          {isError ? (
            <div className="text-sm text-white text-center ph-2">
              Datenquelle nicht verfügbar
            </div>
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
  );
};

export default LeaderboardFull;

import create from "zustand";

interface LayoutState {
  logoFixed: boolean;
  hideLogos: boolean;
  setLogoFixed: (logoFixed: boolean) => void;
  setHideLogos: (hideLogos: boolean) => void;
}

const useLayoutStore = create<LayoutState>((set, get) => ({
  logoFixed: true,
  hideLogos: false,
  setLogoFixed: (logoFixed: boolean) => set((state) => ({ logoFixed })),
  setHideLogos: (hideLogos: boolean) => set((state) => ({ hideLogos })),
}));

export default useLayoutStore;

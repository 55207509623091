import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import api, { hole_url } from "../../misc/api";
import { Hole, HoleSchema } from "../../types/hole";
import baseOptions from "./baseOptions";

export async function fetchHole(holeNumber: number | null) {
  const { data }: AxiosResponse<Hole> = await api.get(
    hole_url(holeNumber || 1),
  );
  const safeParsedData = HoleSchema.parse(data);
  return safeParsedData;
}

function useHole(holeNumber: number | null) {
  return useQuery(["hole", holeNumber], () => fetchHole(holeNumber), {
    ...baseOptions,
    enabled: holeNumber !== null,
  });
}

export default useHole;

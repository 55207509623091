import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { usePlayer } from "../../../hooks/queries/usePlayers";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  currentTournamentRound,
  filterPlayedHoles,
  getFormattedScore,
} from "../../../misc/helpers";
import i18n from "../../../misc/i18n";
import { PlayerScorecard } from "../../../types/players";
import TopNavigation from "../../layout/TopNavigation";
import Avatar from "../../shared/Avatar";
import FavButton from "../../shared/FavButton";
import Loading from "../../shared/Loading";
import TopLeaderboard from "../../shared/TopLeaderboard";
import PlayerDetailScoreboards from "./PlayerDetailScoreboards";
import PlayerStat from "./PlayerStat";
import PlayerStatBox from "./PlayerStatBox";
import { ReactComponent as NTTDataLight } from "./../../../assets/icons/icons-nttdata-light.svg";
import ScorecardLegend from "./ScorecardLegend";
import useLeaderboard from "../../../hooks/queries/useLeaderboard";
import PlayerStatCircle from "./PlayerStatCircle";
import { BASE_TITLE } from "../../../misc/config";
import PlayerAvgStat from "./PlayerAvgStat";
import useLayoutStore from "../../../hooks/store/useLayoutStore";

type PlayerProps = {};

const Player: React.FC<PlayerProps> = () => {
  const { id } = useParams<{ id?: string | undefined }>();
  const { data: player } = usePlayer(Number(id));
  const [selectedDay] = useState<number | null>(null);
  const { isMobile, innerWidth } = useIsMobile();
  const { data: leaderboard } = useLeaderboard();
  const { setLogoFixed } = useLayoutStore();

  useEffect(() => {
    setLogoFixed(false);
    window.scrollTo(0, 0);
    return () => setLogoFixed(true);
  }, [setLogoFixed]);

  const currentDay = selectedDay || currentTournamentRound;

  const currentRound: PlayerScorecard = player
    ? // @ts-ignore
      player.scorecard[`r${currentDay}`] ||
      // @ts-ignore
      player.scorecard[`r${currentDay - 1}`]
    : null;
  const currentHole = currentRound
    ? currentRound.filter(filterPlayedHoles).length
    : null;
  const fieldLength = leaderboard ? leaderboard.length : 0;

  const fullPageTitle = `${BASE_TITLE} -
          ${
            player
              ? `${player.profile.firstName} ${player.profile.lastName}`
              : "Player"
          }`;

  let avgPuttGraphValue = 0;
  if (player) {
    if (player.stats.puttingAverage !== 0) {
      if (player.stats.puttingAverage > 1.99) {
        avgPuttGraphValue = (player.stats.puttingAverage - 2) * 2;
      } else {
        avgPuttGraphValue = player.stats.puttingAverage - 2;
      }
    }
  }
  return (
    <>
      <Helmet>
        <title>{fullPageTitle}</title>
        <meta property="og:title" content={fullPageTitle} />
      </Helmet>
      <div className="h-full bg-black bg-opacity-20">
        <TopLeaderboard />
        <TopNavigation />

        {!player ? (
          <div style={{ height: 940, minHeight: 940 }}>
            <Loading />
          </div>
        ) : (
          <div className="mt-32 md:mt-48 pb-24">
            <div className="flex flex-1 flex-col lg:flex-row mt-32 md:mt-28 px-5 md:px-24 md:items-center relative">
              <div className="flex flex-1 flex-row items-center">
                <div className="relative">
                  <Avatar
                    isFav={false}
                    size={isMobile ? 64 : 120}
                    playerId={String(player.profile.playerId)}
                    playerName={`${player.profile.firstName}_${player.profile.lastName}`}
                  />
                  <div className="absolute right-0 bottom-0 w-6 h-6 md:w-8 md:h-8 rounded-full bg-white bg-opacity-80 items-center flex justify-center">
                    <FavButton
                      size={isMobile ? 16 : 22}
                      playerId={player.profile.playerId}
                    />
                  </div>
                </div>
                <div className="ml-8 md:ml-16 flex flex-col text-white">
                  <h1 className="text-white font-light text-2xl md:text-5xl tracking-wider uppercase">
                    {player.profile.firstName} {player.profile.lastName}
                  </h1>
                  <div className="flex flex-1 flex-row font-bold text-sm mt-2 md:mt-5">
                    <div className="pr-12 md:pr-0">
                      {player.profile.country}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex max-w-full">
                <div className="flex flex-1 flex-row justify-between my-4 md:my-0 md:justify-end">
                  <div className="flex flex-col text-center mx-4 md:mx-8">
                    <div>
                      <div className="text-white font-light text-3xl tracking-wider">
                        {player.profile.rank}
                      </div>
                      <div className="text-white text-tiny uppercase tracking-wider font-bold pt-3">
                        Position
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col text-center mx-4 md:mx-8">
                    <div>
                      <div className="text-white font-light text-3xl">
                        {/* Number(player.profile.toParToday) > 0 ? "+" : "" */}
                        {getFormattedScore(player.profile.toParToday)}
                      </div>
                      <div className="text-white text-tiny uppercase tracking-wider font-bold pt-3">
                        Today
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col text-center mx-4 md:mx-8">
                    <div>
                      <div className="text-white font-light text-3xl">
                        {currentHole === 18 ? "F" : currentHole || "-"}
                        {/* @ts-ignore */}
                        {player.profile.startHole
                          ? //@ts-ignore
                            player.profile.startHole === 10
                            ? "*"
                            : ""
                          : ""}
                      </div>
                      <div className="text-white text-tiny uppercase tracking-wider font-bold pt-3">
                        {i18n.leaderboard.through}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col text-center mx-4 md:mx-8">
                    <div>
                      <div className="text-white font-light text-3xl">
                        {getFormattedScore(player.profile.toPar)}
                      </div>
                      <div className="text-white text-tiny uppercase tracking-wider font-bold pt-3">
                        Score
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4 md:my-28 md:mb-12">
              <PlayerDetailScoreboards
                key={player.profile.playerId}
                player={player}
                currentHole={currentHole}
                isMobile={innerWidth < 1190}
              />
              <div className="flex flex-1 flex-row justify-end px-4 md:px-24 py-6">
                <ScorecardLegend />
              </div>
            </div>

            <div className="flex flex-1 flex-row justify-between mx-4 md:mx-24 mb-4">
              <h3 className="text-xxs font-bold tracking-wider uppercase text-white">
                Stats
              </h3>
              <div>
                <NTTDataLight className="w-32" />
              </div>
            </div>

            <div className="flex flex-1 flex-row md:justify-center px-4 md:px-24 pt-4 pb-8 md:pb-16 md:overflow-hidden overflow-x-auto">
              <div className="flex mx-6">
                <PlayerStatCircle
                  statValue={Number(player.stats.gir.toFixed(0))}
                  statExtension="%"
                  statTitle={i18n.player.greenReg}
                  isMobile={isMobile}
                />
              </div>
              <div className="flex mx-6">
                <PlayerStatCircle
                  statValue={Number(player.stats.fairwayAccuracy.toFixed(0))}
                  statExtension="%"
                  statTitle={i18n.player.teeshotInFairway}
                  isMobile={isMobile}
                />
              </div>
              <div className="flex mx-6">
                <PlayerStatCircle
                  statValue={Number(player.stats.birdieHitRate.toFixed(0))}
                  statExtension="%"
                  statTitle={i18n.player.birdieHitRage}
                  isMobile={isMobile}
                />
              </div>
            </div>

            <div className="my-4">
              <PlayerStat
                statTitle={i18n.player.longestDrive}
                statValue={Number(player.stats.longestDrive.toFixed(0))}
                statVisualValue={(100 * player.stats.longestDrive) / 400}
                statValueExtension="m"
                startLegend="0"
                endLegend="400"
              />
            </div>
            <div className="my-4">
              <PlayerAvgStat
                statTitle={i18n.player.puttsAvg}
                statValue={player.stats.puttingAverage}
                statVisualValue={avgPuttGraphValue}
                statValueExtension="Putts"
                startLegend="1.2"
                endLegend="2.5"
              />
            </div>

            <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-5 px-4 md:px-24 mt-16">
              <div className="">
                <PlayerStatBox
                  statTitle={i18n.player.girField}
                  statValue={player.stats.girRank}
                  statValueExtension={`/ ${fieldLength}`}
                />
              </div>
              <div className="">
                <PlayerStatBox
                  statTitle={i18n.player.fairwayAccField}
                  statValue={player.stats.fairwayAccuracyRank}
                  statValueExtension={`/ ${fieldLength}`}
                />
              </div>
              <div className="">
                <PlayerStatBox
                  statTitle={i18n.player.longestDriveField}
                  statValue={player.stats.longestDriveRank}
                  statValueExtension={`/ ${fieldLength}`}
                />
              </div>
              <div className="">
                <PlayerStatBox
                  statTitle={i18n.player.birdieRateField}
                  statValue={player.stats.birdieHitRateRank}
                  statValueExtension={`/ ${fieldLength}`}
                />
              </div>
              <div className="">
                <PlayerStatBox
                  statTitle={i18n.player.puttAvgField}
                  statValue={player.stats.puttingAverageRank}
                  statValueExtension={`/ ${fieldLength}`}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Player;

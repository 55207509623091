import moment from "moment";
import { useEffect, useState } from "react";

export default function useCountdown({ finishDate }: { finishDate: string }): {
  timeLeft: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
} {
  const calculateTimeLeft = () => {
    const end = moment(finishDate, "DD.MM.YYYY HH:mm");
    const timeLeft = moment.duration(end.diff(moment()));

    return {
      days: timeLeft.days(),
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes(),
      seconds: timeLeft.seconds(),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const time = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(time);
  });

  return {
    timeLeft,
  };
}

import create from "zustand";
import { persist } from "zustand/middleware";

interface CookieState {
  cookieAccept: boolean;
  acceptedAt: Date | null;
  setCookieAccept: () => void;
}

const useCookieStore = create(
  persist<CookieState>(
    (set, get) => ({
      cookieAccept: false,
      acceptedAt: null,
      setCookieAccept: () =>
        set(() => ({
          cookieAccept: true,
          acceptedAt: new Date(),
        })),
    }),
    {
      name: "bmwi-cookie-accept",
    }
  )
);

export default useCookieStore;

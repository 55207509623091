export enum TickerEventType {
  "new_leader",
  "new_co_leader",
  "hole_in_one",
  "albatross",
  "eagle",
  "birdie",
  "par",
  "bogey",
  "double_bogey",
  "triple_bogey",
  "hot_streak",
  "cold_streak",
  "round_complete",
  "half_round",
  "saved_par_from_sand",
  "saved_par_from_rough",
  "missed_birdie_putt",
  "triple_putt",
}

enum Tags {
  // Scores
  ScoreBigAlbatrossSimpleScore = "score_big_albatross_simple_score",
  ScoreBigEagleSimpleScore = "score_big_eagle_simple_score",
  ScoreBigBirdieSimpleScore = "score_big_birdie_simple_score",
  ScoreSmallBogeySimpleScore = "score_small_bogey_simple_score",
  ScoreSmallDoubleBogeySimpleScore = "score_small_double_bogey_simple_score",
  ScoreSmallTripleBogeySimpleScore = "score_small_triple_bogey_simple_score",
  ScoreSmallParSimpleScore = "score_small_par_simple_score",

  PlayerHoleNewPlayers = "player_hole_new_players",

  // NewLeaders
  BigNewLeader = "big_new_leader",
  BigNewCoLeader = "big_new_co_leader",

  // HoleInOnes
  ScoreBigHoleInOne = "score_big_hole_in_one",

  // Streaks
  ScoreStreakHotStreak = "score_streak_hot_streak",
  ScoreStreakColdStreak = "score_streak_cold_streak",

  // CompletedRounds
  HalfRoundScoreSimpleScore = "half_round_score_simple_score",
  RoundCompleteBig = "round_complete_big",

  // NewPlayers
  PlayerNewCoPlayers = "player_new_co_players",

  // TriplePutts
  ScoreTriplePutt = "score_triple_putt",

  // MissedBirdiePutt
  ScoreMissedBirdie = "score_missed_birdie",

  // NotableRoutes
  ScoreRouteSandSimpleScore = "score_route_sand_simple_score",
  ScoreRouteRoughSimpleScore = "score_route_rough_simple_score",
}

export const tagsI18nStrings: { [key in Tags]: string } = {
  // Scores
  [Tags.ScoreBigAlbatrossSimpleScore]: "Albatross scored",
  [Tags.ScoreBigEagleSimpleScore]: "Eagle scored",
  [Tags.ScoreBigBirdieSimpleScore]: "Birdie scored",
  [Tags.ScoreSmallBogeySimpleScore]: "Bogey scored",
  [Tags.ScoreSmallDoubleBogeySimpleScore]: "Double Bogey scored",
  [Tags.ScoreSmallTripleBogeySimpleScore]: "Triple Bogey scored",
  [Tags.ScoreSmallParSimpleScore]: "Par scored",

  // NewLeaders
  [Tags.BigNewLeader]: "New Leader",
  [Tags.BigNewCoLeader]: "New Co-Leader",

  // PlayerHoleNewPlayers
  [Tags.PlayerHoleNewPlayers]: "Player on Hole",

  // HoleInOnes
  [Tags.ScoreBigHoleInOne]: "Hole in One",

  // Streaks
  [Tags.ScoreStreakHotStreak]: "Hot Streak",
  [Tags.ScoreStreakColdStreak]: "Cold Streak",

  // CompletedRounds
  [Tags.HalfRoundScoreSimpleScore]: "Half Round Completed",
  [Tags.RoundCompleteBig]: "Round Complete",

  // NewPlayers
  [Tags.PlayerNewCoPlayers]: "New Co-Players",

  // TriplePutts
  [Tags.ScoreTriplePutt]: "Triple Putt",

  // MissedBirdiePutt
  [Tags.ScoreMissedBirdie]: "Missed Birdie Putt",

  // NotableRoutes
  [Tags.ScoreRouteSandSimpleScore]: "Save",
  [Tags.ScoreRouteRoughSimpleScore]: "Save",
};

export type TickerItem = {
  hole: number;
  priority: number;
  headline: string;
  details: string;
  itemId: string;
  playerIds: number[];
  sequence: number;
  type: Tags;
  createdAt: string;
};

import CountdownTournamentDay from "../shared/CountdownTournamentDay";

function Landing() {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <div className="flex">
        <h2 className="text-white text-xl text-center md:text-3xl md-2 md:mb-11 px-6 leading-10">
          Der Countdown zur BMW International Open 2024 läuft. <br /> Die
          Digitale Live Experience kehrt mit Turnierstart zurück.
        </h2>
      </div>
      <div className="flex mt-8">
        <CountdownTournamentDay />
      </div>
    </div>
  );
}

export default Landing;

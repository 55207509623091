const Progressbar: React.FC<{ progress: number }> = ({ progress }) => (
  <div className="w-full bg-very_light_pink h-0.5 relative">
    <div
      className="bg-white_grey text-xs leading-none absolute text-center text-white transition-none duration-200 ease-in"
      style={{ width: "100%", top: 0, height: 2 }}
    ></div>
    <div
      className="bg-blue text-xs leading-none absolute h-1 text-center text-white transition-none duration-200 ease-in"
      style={{ width: progress + "%", top: -1 }}
    ></div>
  </div>
);

export default Progressbar;

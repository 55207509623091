import { z } from "zod";

export enum LeaderboardPlayerStatus {
  Uncut = "Uncut",
  Cut = "Cut",
  Withdrawn = "Withdrawn",
  Retired = "Retired",
  Disqualified = "Disqualified",
  DidNotStart = "DidNotStart",
  MadeCutButDidNotFinish = "MadeCutButDidNotFinish",
  Other = "Other",
  Removed = "Removed",
}

export const LeaderboardPlayerStatusSchema = z.enum([
  LeaderboardPlayerStatus.Uncut,
  LeaderboardPlayerStatus.Cut,
  LeaderboardPlayerStatus.Withdrawn,
  LeaderboardPlayerStatus.Retired,
  LeaderboardPlayerStatus.Disqualified,
  LeaderboardPlayerStatus.DidNotStart,
  LeaderboardPlayerStatus.MadeCutButDidNotFinish,
  LeaderboardPlayerStatus.Other,
  LeaderboardPlayerStatus.Removed,
]);

// Define default values
const defaultValues = {
  playerId: 0,
  firstName: "",
  lastName: "",
  country: "",
  isAmateur: false,
  rank: "0",
  rankOrder: 0,
  previousRank: null,
  startHole: null,
  teeTime: null,
  holesThrough: null,
  totalScore: null,
  toPar: "0",
  totalScoreToday: null,
  toParToday: null,
  status: "Other" as LeaderboardPlayerStatus, // Default status
  isPlayoffRound: false,
};

// Define the schema with default values
export const LeaderboardItemSchema = z.object({
  playerId: z.number().int().catch(defaultValues.playerId),
  firstName: z.string().catch(defaultValues.firstName),
  lastName: z.string().catch(defaultValues.lastName),
  country: z.string().length(3).catch(defaultValues.country),
  isAmateur: z.boolean().catch(defaultValues.isAmateur),
  rank: z.string().catch(defaultValues.rank),
  rankOrder: z.number().int().catch(defaultValues.rankOrder),
  previousRank: z.string().nullable().catch(defaultValues.previousRank),
  startHole: z
    .union([z.literal(1), z.literal(10)])
    .nullable()
    .catch(defaultValues.startHole),
  teeTime: z.string().nullable().catch(defaultValues.teeTime), // Optionally, use a regex to validate ISO 8601 format
  holesThrough: z.number().int().nullable().catch(defaultValues.holesThrough),
  totalScore: z.number().int().nullable().catch(defaultValues.totalScore),
  toPar: z.string().catch(defaultValues.toPar),
  totalScoreToday: z
    .number()
    .int()
    .nullable()
    .catch(defaultValues.totalScoreToday),
  toParToday: z
    .string()
    .nullable()
    .transform((v) => (v === "-" ? null : v))
    .catch(defaultValues.toParToday),
  status: LeaderboardPlayerStatusSchema.catch(defaultValues.status),
  isPlayoffRound: z.boolean().catch(defaultValues.isPlayoffRound),
});

export type LeaderboardItem = z.infer<typeof LeaderboardItemSchema>;

const PlayerStatBox: React.FC<{
  statTitle: string;
  statValue: number;
  statValueExtension: string;
}> = ({ statTitle, statValue, statValueExtension }) => {
  return (
    <div className="w-full h-32 bg-white bg-opacity-10">
      <div className="flex flex-1 h-full flex-col p-4 pt-4 pb-2">
        <div className="text-sm md:text-xs font-semibold text-white tracking-wider uppercase w-full">
          {statTitle}
        </div>
        <div className="flex flex-1 justify-end items-end">
          <div className="text-3xl font-normal text-white tracking-wider">
            {statValue}
          </div>
          <div className="text-middle_grey font-semibold tracking-wider xt-sm pb-2">
            {statValueExtension}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerStatBox;

import React, { useEffect, useMemo, useState } from "react";
import useFavoritesStore from "../../../hooks/store/useFavoritesStore";
import useIsMobile from "../../../hooks/useIsMobile";
import ads from "../../../misc/ads_2024";
import i18n from "../../../misc/i18n";
import { LeaderboardItem } from "../../../types/leaderboard";
import LeaderboardListItem from "./LeaderboardListItem";

type LeaderboardListProps = {
  players?: LeaderboardItem[];
  isLoading?: boolean;
  fetching?: boolean;
  projectedCutScore?: string | null;
};

const BASE_INTERVAL = 10000;
const PLAYER_TO_AD_RATIO = 25;

const PartnerAd: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [adIndex, setAdIndex] = useState(0);

  useEffect(() => {
    const nextAd = ads[adIndex];

    let interval = setInterval(
      () => {
        if (adIndex + 1 < ads.length) {
          setAdIndex((index) => index + 1);
        } else {
          setAdIndex(0);
        }
      },
      nextAd ? nextAd.time || BASE_INTERVAL : BASE_INTERVAL,
    );
    return () => clearInterval(interval);
  }, [adIndex]);

  const ad = ads[adIndex];
  const MAX_HEIGHT = isMobile ? 120 : 220;
  if (!ad) return null;

  return (
    <div
      className="mb-2 md:my-4 md w-full flex flex-1 items-center justify-center overflow-hidden"
      style={{
        height: MAX_HEIGHT,
        maxHeight: MAX_HEIGHT,
        minHeight: MAX_HEIGHT,
        overflow: "hidden",
      }}
    >
      {ad.link ? (
        <a
          href={ad.link}
          title={ad.title}
          target="_blank"
          rel="noreferrer"
          className="partner-link overflow-hidden"
        >
          {isMobile ? ad.imgMobile : ad.imgDesktop}
        </a>
      ) : isMobile ? (
        ad.imgMobile
      ) : (
        ad.imgDesktop
      )}
    </div>
  );
};
const LeaderboardList: React.FC<LeaderboardListProps> = ({
  players = [],
  projectedCutScore,
  isLoading,
  fetching,
}) => {
  const { favs } = useFavoritesStore();
  const { isMobile } = useIsMobile();

  const PartnerAdMemo = useMemo(
    () => <PartnerAd isMobile={isMobile} />,
    [isMobile],
  );

  const favsPlayersList = useMemo(
    () =>
      players
        .filter((player) => favs.includes(player.playerId))
        .sort(
          // @ts-ignore
          (a: LeaderboardItem, b: LeaderboardItem) =>
            // @ts-ignore
            a.rank - b.rank,
        ),
    [players, favs],
  );

  const firstCutPlayerId = useMemo(() => {
    return players.find((p) => p.status === "Cut")?.playerId;
  }, [players]);

  const showListTitles = favsPlayersList.length > 0;

  return (
    <div className="md:px-24 md:mt-24 pb-24" style={{ minWidth: 250 }}>
      <div
        className="bg-opacity-95 z-40 flex flex-1 flex-row sticky top-0 justify-start text-middle_grey text-tiny tracking-wider uppercase font-bold pr-2 pl-4 md:px-5 pb-3 pt-3"
        style={{ backgroundColor: "#272a24" }}
      >
        <div className="w-8 md:w-14">{i18n.leaderboard.fav}</div>
        <div className="w-8 md:w-14">{i18n.leaderboard.pos}</div>
        <div className="flex flex-1 md:flex-none md:w-64">
          {i18n.leaderboard.player}
        </div>
        <div className="flex flex-1 flex-row justify-end items-end">
          <div
            className="flex flex-grow flex-row justify-end items-end self-end"
            style={{
              maxWidth: 450,
              alignSelf: "flex-end",
            }}
          >
            <div className="flex-1 hidden md:block">Country</div>
            <div className="flex-1 hidden md:block">Today</div>
            <div className="w-14 md:flex-1">{i18n.leaderboard.through}</div>
            <div className="hidden md:block w-10 md:flex-1">Score</div>
            <div className="md:hidden block w-10 md:flex-1">Today</div>
            <div className="flex-1 hidden md:block md:flex-1">Total Today</div>
            <div className="w-10">{i18n.leaderboard.totalScore}</div>
          </div>
        </div>
      </div>

      {showListTitles ? (
        <>
          <div className="w-24 h-5 bg-blue flex items-center justify-center">
            <h3 className="p-0 m-0 text-white text-tiny tracking-wider font-bold text-center uppercase">
              {i18n.leaderboard.favourites}
            </h3>
          </div>
          <div className="flex flex-1 flex-col justify-start text-middle_grey text-tiny tracking-wider uppercase font-bold">
            {favsPlayersList.map((favPlayer, i) =>
              isMobile ? (
                <LeaderboardListItem
                  key={`fav_${favPlayer.playerId}`}
                  index={i}
                  player={favPlayer}
                />
              ) : (
                <LeaderboardListItem
                  key={`fav_${favPlayer.playerId}`}
                  index={i}
                  player={favPlayer}
                />
              ),
            )}
          </div>
        </>
      ) : null}

      {showListTitles ? (
        <div className="w-24 h-5 bg-dark_grey flex items-center justify-center mt-6 md:mt-14">
          <h3 className="p-0 m-0 text-white text-tiny tracking-wider font-bold text-center uppercase">
            Leaderboard
          </h3>
        </div>
      ) : null}
      <div className="flex flex-1 flex-col justify-start text-middle_grey text-tiny tracking-wider uppercase font-bold">
        {players.map((player, i) => (
          <React.Fragment key={`main_${player.playerId}`}>
            {i > 0 && (i === 5 || i % PLAYER_TO_AD_RATIO === 0)
              ? PartnerAdMemo
              : null}
            {(projectedCutScore === player.toPar &&
              players[i - 1].toPar !== player.toPar) ||
            firstCutPlayerId === player.playerId ? (
              <div className="w-full h-5 border-b-4 mt-4 border-blue flex items-center justify-start">
                <div className="w-24 h-5 bg-blue flex items-center justify-center">
                  <h3 className="p-0 m-0 text-white text-tiny tracking-wider font-bold text-center uppercase">
                    {player.status === "Uncut"
                      ? i18n.leaderboard.projectedCut
                      : i18n.leaderboard.missedCut}
                  </h3>
                </div>
              </div>
            ) : null}
            <LeaderboardListItem index={i} player={player} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default LeaderboardList;

import { useRef } from "react";
import useIntersectionObserver from "../../../hooks/useIntersection";

const PlayerAvgStat: React.FC<{
  startLegend?: string;
  endLegend?: string;
  statTitle: string;
  statValue: number;
  statVisualValue: number;
  statValueExtension: string;
}> = ({
  statTitle,
  statValue,
  statVisualValue,
  statValueExtension,
  startLegend = "",
  endLegend = "",
}) => {
  const ref = useRef<HTMLDivElement | any>();
  const intersectionEntry = useIntersectionObserver(ref, {
    threshold: 0.5,
    freezeOnceVisible: true,
  });
  const inViewport = intersectionEntry && intersectionEntry.isIntersecting;

  const calcedStyles = {
    width: "50%",
    transform: `scaleX(${inViewport ? statVisualValue : 0})`,
    transformOrigin: "left",
    marginLeft: "50%",
  };

  return (
    <div>
      <div
        ref={ref}
        className="flex flex-1 flex-col md:flex-row bg-white bg-opacity-10 p-2 items-start px-5 md:px-24 pt-4"
      >
        <div className="text-white text-xxs uppercase tracking-wider font-normal pr-4 w-48">
          {statTitle}
        </div>
        <div className="flex flex-1 w-full items-start">
          <div className="flex flex-col flex-1 pt-2">
            <div className="flex flex-1 relative">
              <div
                className="h-2 z-20 relative bg-gradient-to-r from-grad_start to-grad_end transition-all duration-300 delay-75"
                style={calcedStyles}
              ></div>
              <div
                className="flex flex-1 absolute justify-start text-xxs font-semibold tracking-wider text-white"
                style={{
                  width: `${statVisualValue * -1 * 50}%`,
                  left: `${statVisualValue * 1 * 50}%`,
                  marginLeft: "50%",
                  marginTop: 12,
                }}
              >
                {statValue !== 0 && statValue !== 2 ? (
                  <div>{statValue}</div>
                ) : null}
              </div>
              <div
                className="w-full absolute z-0 bg-dark_grey transition-all duration-300"
                style={{
                  height: 2,
                  top: 3,
                }}
              ></div>
            </div>
            <div className="flex flex-row justify-between text-xxs font-semibold text-white mt-1 tracking-wider">
              <div>{startLegend}</div>
              <div>2</div>
              <div>{endLegend}</div>
            </div>
          </div>
          <div className="flex flex-row justify-end items-end w-36">
            <div className="text-white font-normal text-3xl tracking-wider">
              {statValue}
            </div>
            <div className="text-white font-bold text-sm pb-2 pl-1 uppercase">
              {statValueExtension}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerAvgStat;

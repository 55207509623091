import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useExternalScript } from "../../../hooks/useExternalScript";
import { BASE_TITLE } from "../../../misc/config";
import { isTournamentOver, livestreamActive } from "../../../misc/helpers";
import TopNavigation from "../../layout/TopNavigation";
import Countdown from "../../shared/Countdown";
import PartnerLogos from "../../shared/PartnerLogos";
import TopLeaderboard from "../../shared/TopLeaderboard";

//const VIMEO_LINK = "https://vimeo.com/event/2230419/embed/7686817114";

const PLAYER_SCRIPT_SRC =
  "https://players.brightcove.net/6058004230001/u66KXhAvb_default/index.min.js";

// const LiveStreamPlayer = memo(() => (
//   <VideoPlayer source={SKY_LIVESTREAM_URL} />
// ));

const Livestream: React.FC<{}> = ({ children }) => {
  const [isLive, setIsLive] = useState(livestreamActive());
  const tournamentOver = isTournamentOver();
  const [activeLivestream] = useState("world");
  const [fullscreen, setFullscreen] = useState(false);

  const showLivestreamElement = isLive;
  const [enabledLivestream] = useState(true);

  let counterInterval = useRef<null | any>(null);

  useEffect(() => {
    counterInterval.current = setInterval(checkCounter, 5000);
    return () => {
      if (counterInterval.current !== null) {
        return clearInterval(counterInterval.current);
      }
    };
  }, [counterInterval, isLive]);

  useEffect(() => {
    function handleFullscreenChange() {
      setFullscreen((f) => !f);
    }
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  function checkCounter() {
    setIsLive(livestreamActive());
  }

  useExternalScript(
    PLAYER_SCRIPT_SRC,
    showLivestreamElement && enabledLivestream && activeLivestream === "world",
  );

  const fullPageTitle = `${BASE_TITLE} - Livestream`;

  const skyPlayerHtml = `
  <div>
    <div style="padding-top: 56.25%;">
      <video-js
      data-account="6058004230001"
      data-player="u66KXhAvb"
      data-embed="default"
      controls=""
      data-video-id="1674116764221155252"
      data-playlist-id=""
      data-application-id=""
      class="vjs-fluid"
      controls=""
      style="top:0; position:absolute; aspect-ratio: 16/9;"></video-js>
    </div>
  </div>`;

  return (
    <div className="xs:mb-16 md:overflow-y-hidden">
      <Helmet>
        <title>{fullPageTitle}</title>
        <meta property="og:title" content={fullPageTitle} />
      </Helmet>
      <TopLeaderboard />
      {!fullscreen ? <TopNavigation /> : null}
      <div
        className={`flex flex-1 justify-center items-center pt-24 md:pt-32 md:pb-0
      `}
      >
        <div
          key={String(isLive)}
          className={`
          w-full h-full
          ${!fullscreen ? "md:pl-24" : ""}
          md:mr-80 xl:mr-96 md:pr-16 xl:pr-24
          relative md:h-screen
        `}
          style={{
            maxWidth: "calc(110vh)",
          }}
        >
          {!tournamentOver ? (
            <>
              <div
                className="bg-black_grey bg-opacity-80 md:max-h-video-wrapper xs:h-full"
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                {isLive ? (
                  <div
                    className="h-full max-h-full"
                    dangerouslySetInnerHTML={{ __html: skyPlayerHtml }}
                  />
                ) : (
                  <div
                    className="flex flex-1 flex-col justify-center items-center countdown-bg bg-cover"
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: "45vh",
                    }}
                  >
                    <div className="flex">
                      <h2 className="text-white text-xl text-center md:text-3xl md-2 md:mb-11 px-6">
                        Die Live-Übertragung der BMW International Open beginnt
                        in
                      </h2>
                    </div>
                    <div className="flex mt-8">
                      <Countdown />
                    </div>
                  </div>
                )}
              </div>
              <div className="absolute">
                <div>
                  <h4 className="font-bold text-sm tracking-wide text-white my-4">
                    {activeLivestream === "world"
                      ? "World feed"
                      : "Featured Holes"}
                  </h4>
                </div>
              </div>
              <div className="w-full hidden md:flex flex-row justify-end">
                <button
                  className={`${
                    fullscreen ? "bg-grey4 text-black" : "bg-blue text-white"
                  } text-xs font-semibold p-2`}
                  onClick={() => {
                    const streamDiv = document.getElementById("stream-div");
                    if (streamDiv) {
                      if (document.fullscreenElement === streamDiv) {
                        document.exitFullscreen();
                      } else {
                        streamDiv.requestFullscreen();
                      }
                    }
                  }}
                >
                  {fullscreen ? "Vollbild verlassen" : "Vollbild mit Ticker"}
                </button>
              </div>
            </>
          ) : null}

          {tournamentOver ? (
            <div
              className="flex flex-1 flex-col justify-center items-center countdown-bg bg-cover"
              style={{
                width: "100%",
                height: "70%",
                minHeight: "35vh",

                // height: "55%",
                // maxWidth: !isMobile ? "calc(100vw - 500px)" : "100%",
                // aspectRatio: "16/9",
                // marginLeft: isMobile ? "0" : "75px",
              }}
            >
              <div className="flex">
                <h2 className="text-white text-xl text-center md:text-3xl mb-11 px-6">
                  Die Live-Übertragung der BMW International Open ist beendet.
                </h2>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {children}
      <div
        className="flex fixed justify-between md:justify-start w-full px-6 items-end bottom-12 md:bottom-0 pointer-events-none"
        style={{
          paddingRight: window.innerWidth < 800 ? 0 : 660,
        }}
      >
        <PartnerLogos />
      </div>
    </div>
  );
};

export default Livestream;

import { useLocation } from "react-router-dom";
import useLayoutStore from "../../hooks/store/useLayoutStore";
import useIsMobile from "../../hooks/useIsMobile";
import { IMPRESS_LINK, PRIVACY_POLICY_LINK } from "../../misc/config";
import PartnerLogos from "../shared/PartnerLogos";

import "./layout.css";

const Layout: React.FC<{}> = ({ children }) => {
  const { logoFixed } = useLayoutStore();
  const location = useLocation();
  const { isMobile } = useIsMobile();

  const courseView = location.pathname.includes("/course");
  const livestream = location.pathname.includes("/livestream");
  const playerView = location.pathname.includes("/players");
  const specialView = courseView;

  return (
    <div
      className={`
        ${courseView ? "map-bg" : "default-bg"}
        ${specialView || (livestream && !isMobile) ? "" : ""}
        ${livestream ? "sm:min-h-screen" : ""}
      `}
    >
      <div
        className="h-full"
        style={
          specialView
            ? {
                maxHeight: window.innerHeight,
                overflow: "hidden",
              }
            : {}
        }
      >
        {children}
      </div>
      {!livestream && !courseView && !playerView ? (
        <div
          className={`
        ${
          logoFixed
            ? "bottom-0 h-16 absolute"
            : "fixed bottom-0 bg-black_grey bg-opacity-80 w-full h-16 z-30"
        }
        flex flex-1 flex-row justify-between items-center px-2 md:px-6 overflow-hidden`}
        >
          <PartnerLogos />
        </div>
      ) : null}
      <div
        className={`hidden md:flex ${
          playerView ? "relative" : "fixed"
        } flex-1 text-light_grey text-xs flex-row items-center justify-center z-40 h-16 w-full bottom-0 font-semibold tracking-wider`}
      >
        <div className="px-4">
          <a
            href={IMPRESS_LINK}
            title="Impress"
            target="_blank"
            rel="noreferrer noopener"
          >
            Imprint
          </a>
        </div>
        <div className="px-4">
          <a
            href={PRIVACY_POLICY_LINK}
            title="Privacy Policy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Layout;

import axios from "axios";

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ?? "https://api.difficult.company/v2";

export const leaderboard_url = `${API_BASE_URL}/leaderboard`;
export const player_url = (id: number) => `${API_BASE_URL}/player/${id}`;
export const players_url = `${API_BASE_URL}/players/all`;
export const news_url = (startAt: number | null) =>
  `${API_BASE_URL}/news/latest${startAt ? `?startAt=${startAt}` : ""}`;
export const hole_url = (holeNumber: number) =>
  `${API_BASE_URL}/hole/${holeNumber}`;
export const course_url = `${API_BASE_URL}/course`;

let api = axios.create();

// add token als default
// saves setting token for every request in queries
api.defaults.params = {
  ...api.defaults.params,
  token: process.env.REACT_APP_API_TOKEN,
};

export default api;

import create from "zustand";
import { persist } from "zustand/middleware";

interface FavState {
  favs: number[];
  toggleFav: (playerId: number) => void;
}

const useFavoritesStore = create(
  persist<FavState>(
    (set, get) => ({
      favs: [],
      toggleFav: (playerId: number) =>
        set((state) => {
          if (state.favs.includes(playerId)) {
            return {
              favs: state.favs.filter((id) => id !== playerId),
            };
          }
          return {
            favs: [...state.favs, playerId],
          };
        }),
    }),
    {
      name: "player-favs",
    }
  )
);

export default useFavoritesStore;

import React from "react";
import { HolePairing } from "../../../types/hole";
import Avatar from "../../shared/Avatar";
import FavButton from "../../shared/FavButton";

type FlightRowProps = {
  player: HolePairing;
  currentHole: number;
  index: number;
  handleRowClick: () => void;
};

const FlightRow: React.FC<FlightRowProps> = ({
  player,
  currentHole,
  index,
  handleRowClick,
}) => {
  return (
    <tr
      onClick={handleRowClick}
      className="cursor-pointer border-b border-very_light_pink"
    >
      <td className="py-3 pl-1 font-bold text-black_grey text-sm whitespace-nowrap">
        <div className="flex flex-row items-center">
          <div className="mr-4">
            <FavButton playerId={player.playerId} size={20} />
          </div>
          <div className="mr-4 w-8 tracking-wider">{player.rank}</div>
          <div className="mr-2 w-10">
            <Avatar
              size={40}
              playerId={String(player.playerId)}
              playerName={player.firstName + " " + player.lastName}
            />
          </div>
          <div className="flex flex-row items-center mr-2 w-36">
            <span className="truncate text-darkgrey font-bold">
              {`${player.firstName} ${player.lastName}`}
            </span>
          </div>
          {/* <div className="mr-2 xs:hidden justify-self-end">
    <img
      src={FLAG_URL}
      alt={player.country}
      title={player.country}
      className="self-end"
      style={{ width: 20 }}
    />
  </div> */}
        </div>
      </td>
      {/* <td className="py-4 text-sm text-center font-bold text-darkgrey whitespace-nowrap">
        <span>{currentHole}</span>
      </td> */}
      <td className="py-4 px-2 text-sm font-bold text-center text-blue whitespace-nowrap">
        <span>{player.toPar}</span>
      </td>
    </tr>
  );
};

export default FlightRow;

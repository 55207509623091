import useCfTrace from "./queries/useCfTrace";

const allowedCountries = ["DE", "DEU", "CH", "CHE", "AT", "AUT"];

export default function useGeoLivestream() {
  const { data: traceData } = useCfTrace();

  let livestreamAvailable = false;
  if (traceData) {
    livestreamAvailable = allowedCountries.includes(traceData.loc);
  }

  return {
    livestreamAvailable,
  };
}

import React, { memo, useEffect, useRef, useState } from "react";
import i18n from "../../misc/i18n";
import { ReactComponent as StarIcon } from "./../../assets/icons/icons-star-full.svg";
import { ReactComponent as Placeholder } from "./../../assets/icons/icons-avatar-placeholder.svg";
import { AVATAR_BASE_URL } from "../../misc/config";
import useIntersectionObserver from "../../hooks/useIntersection";

type AvatarProps = {
  playerName: string;
  playerId: string | null;
  showFav?: boolean;
  isFav?: boolean;
  handleFav?: (e: any) => void;
  size?: number | string;
};

const Avatar: React.FC<AvatarProps> = memo(
  ({
    playerName,
    playerId,
    size = 36,
    showFav = false,
    handleFav,
    isFav = false,
  }) => {
    const ref = useRef<HTMLDivElement | any>();
    const intersectionEntry = useIntersectionObserver(ref, {
      threshold: 0.5,
      freezeOnceVisible: true,
    });
    const [image, setImage] = useState<null | string>(null);
    const inViewport = intersectionEntry && intersectionEntry.isIntersecting;

    useEffect(() => {
      function checkPlayerImage() {
        if (playerId == null || !inViewport || image) {
          return false;
        }
        const imgUrl = `${AVATAR_BASE_URL}/${playerId}.jpg?v2`;
        const img = new Image();
        img.onload = () => {
          setImage(img.src);
        };
        img.onerror = () => {
          setImage(null);
        };

        img.src = imgUrl;
        img.id = `player-avatar-${playerId}`;

        if (img.complete) {
          setImage(img.src);
        }
      }
      checkPlayerImage();

      return () => {
        const imgEl = document.getElementById(`player-avatar-${playerId}`);
        if (imgEl) {
          setImage(null);
          imgEl.remove();
        }
      };
    }, [playerId, inViewport, image]);

    return (
      <div
        ref={ref}
        className={`rounded-full relative z-0`}
        style={{
          width: size,
          height: size,
        }}
      >
        {image && inViewport ? (
          <img
            src={`${AVATAR_BASE_URL}/${playerId}.jpg?v2`}
            alt={playerName}
            className="rounded-full overflow-hidden"
          />
        ) : (
          <Placeholder
            title={playerName}
            className=""
            height={size}
            width={size}
          />
        )}
        {showFav ? (
          <div
            onClick={handleFav}
            className="absolute -bottom-1 -right-1 w-6 h-6 flex flex-1 justify-end items-end"
          >
            <StarIcon
              title={
                isFav ? i18n.player.removeFavPlayer : i18n.player.favPlayer
              }
              className={`${isFav ? "text-blue" : "text-darkgrey"} ${
                handleFav ? "hover:text-blue cursor-pointer" : ""
              }`}
              height={18}
              width={18}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default Avatar;

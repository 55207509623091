// basic lang. file
const en = {
  title: {
    bmwi: "BMW International Open",
  },
  actions: {
    goBack: "Go back",
  },
  tabs: {
    day: "Day",
    dayStatus: {
      0: "Replay",
      1: "Live",
      2: "Upcoming",
    },
  },
  countdown: {
    days: "tage",
    day: "tag",
    hours: "stunden",
    hour: "stunde",
    minutes: "minuten",
    minute: "minute",
    seconds: "sekunden",
    second: "sekunde",
    live: "live",
    nextLiveStream: "Nächster Livestream",
  },
  player: {
    playerProfile: "Player profile",
    teeshotInFairway: "Teeshots landed in fairway",
    tournamentStats: "Tournament Stats",
    drivingAcc: "Driving Acc",
    driving: "Driving",
    gir: "GIR",
    greenReg: "Green in regulation",
    puttsAvg: "Average Putts per hole",
    puttsPerGir: "Putts per GIR",
    scrambles: "Scrambles",
    longestDrive: "Longest Drive",
    birdieHitRage: "Birdie Hit Rate",
    favPlayer: "Favorite Player",
    removeFavPlayer: "Remove from Favorites",
    girField: "GIR rank Against The field",
    fairwayAccField: "Fairway accuracy rank against the field",
    longestDriveField: "longest drive rank against the field",
    birdieRateField: "birdie hit rate rank against the field",
    puttAvgField: "putting average rank against the field",
  },
  leaderboard: {
    missedCut: "Missed Cut",
    title: "Leaderboard",
    through: "Through",
    round: "Round",
    fav: "Fav",
    pos: "Pos",
    hole: "Hole",
    holes: "Holes",
    score: "Score",
    totalScore: "Total",
    favourites: "FAVOURITES ",
    viewProfile: "VIEW PLAYER PROFILE",
    player: "Player",
    fullLeaderboard: "Full Leaderboard",
    myLeaderboard: "My Leaderboard",
    projectedCut: "Projected Cut",
    status: {
      Cut: "Cut",
      Withdrawn: "W/D",
      Retired: "RET.",
      Disqualified: "DQ",
      DidNotStart: "DNS",
      MadeCutButDidNotFinish: "DNF",
      Other: "-",
      Removed: "-",
    },
  },
  ticker: {
    newTicker: "Neuer Ticker",
    title: "Ticker",
    soon: "Ticker starts soon",
  },
  hole: {
    scoring: {
      eagleBetter: "Eagle or better",
      birdie: "Birdie",
      bogey: "Bogey",
      double: "Double Bogey",
    },
    title: "Holes",
    number: "Number",
    par: "Par",
    yards: "Yards",
    meters: "Meters",
    metersShort: "M",
    yardsShort: "YD",
    strokeIndexShort: "SI",
    strokeIndex: "Stroke Index",
    hitsInReg: "Hits in Regulation",
    green: "Green",
    greens: "Greens",
    fairway: "Fairway",
    fairways: "Fairways",
    holesBelowPar: "Holes below Par",
    flight: "Flight",
    avgScore: "Average Score",
    avgPutts: "Average Putts",
    hitsInRegCompared: "Hits in regulation compared to all Holes",
    hardestToHit: "Hardest to hit",
    under: "Under",
    over: "Over",
    courseOverview: "Platzübersicht",
  },

  messages: {
    networkError: "Content could not be loaded",
  },

  dashboard: {
    title: "Dashboard",
  },

  stream: {
    noStream: "Livestream inactive until end of countdown.",
  },
};

export default en;

import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import api, { course_url } from "../../misc/api";
import { CourseHole } from "../../types/course";

export async function fetchCourse() {
  const { data }: AxiosResponse<CourseHole[]> = await api.get(course_url);
  return data;
}

function useCourse() {
  return useQuery("course", fetchCourse);
}

export default useCourse;

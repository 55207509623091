import React from "react";
import HeadlineSmall from "./HeadlineSmall";
import ProgressBarSplit from "./ProgressBarSplit";

export type SplitStat = {
  statValue: number | string;
  statType: string;
  statTitle: string;
};

type HoleStatsSplitProps = {
  statTitle: string;
  statValues: SplitStat[];
  statMainValue: number | string;
  avg?: boolean;
};

const HoleStatsSplit: React.FC<HoleStatsSplitProps> = ({
  statTitle,
  statValues,
  statMainValue,
  avg = false,
}) => {
  return (
    <div className="flex flex-col flex-1">
      <div
        className="flex flex-row justify-between items-baseline"
        style={{ paddingBottom: 10 }}
      >
        <div>
          <HeadlineSmall>{statTitle}</HeadlineSmall>
        </div>
        <div>
          <div className="text-3xl">{statMainValue || "-"}</div>
        </div>
      </div>
      <div>
        <ProgressBarSplit
          progress={statValues}
          avgValue={avg ? Number(statMainValue) : null}
          showText
        />
      </div>
    </div>
  );
};

export default HoleStatsSplit;

const coords = [
  {
    hole: 1,
    x: 745,
    y: 333,
  },
  {
    hole: 2,
    x: 810,
    y: 433,
  },
  {
    hole: 3,
    x: 810,
    y: 533,
  },
  {
    hole: 4,
    x: 775,
    y: 720,
  },

  {
    hole: 5,
    x: 720,
    y: 784,
  },
  {
    hole: 6,
    x: 540,
    y: 714,
  },
  {
    hole: 7,
    x: 440,
    y: 654,
  },
  {
    hole: 8,
    x: 695,
    y: 584,
  },
  {
    hole: 9,
    x: 675,
    y: 454,
  },
  {
    hole: 10,
    x: 605,
    y: 470,
  },
  {
    hole: 11,
    x: 535,
    y: 440,
  },
  {
    hole: 12,
    x: 505,
    y: 300,
  },
  {
    hole: 13,
    x: 455,
    y: 410,
  },
  {
    hole: 14,
    x: 375,
    y: 540,
  },
  {
    hole: 15,
    x: 320,
    y: 415,
  },
  {
    hole: 16,
    x: 335,
    y: 355,
  },
  {
    hole: 17,
    x: 205,
    y: 175,
  },
  {
    hole: 18,
    x: 385,
    y: 250,
  },
];

export default coords;

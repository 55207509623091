import React from "react";
import Progressbar from "./Porgressbar";

type HoleStatsProps = {
  statTitle: string | React.ReactNode;
  statValue: number | string;
  statType: string;
  // used if progressbar value
  // should be different from statValue
  progressValue?: number;
  subTitle?: string;
};

const HoleStats: React.FC<HoleStatsProps> = ({
  statTitle,
  statType,
  statValue,
  progressValue = null,
  subTitle,
}) => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-row items-baseline pb-2">
        <div className="flex-1 flex-row flex items-baseline">
          <div className="text-3xl">{statValue || "0"}</div>
          <div className="text-tiny text-brown_grey uppercase pl-1 font-semibold">
            {statType}
          </div>
        </div>
        <div className="flex-1 text-right">
          <div className="text-tiny font-bold text-middle_grey uppercase">
            {statTitle}
          </div>
        </div>
      </div>
      <Progressbar progress={Number(progressValue || statValue)} />
      {subTitle ? (
        <div className="text-tiny font-bold text-brown_grey uppercase pt-2">
          {subTitle}
        </div>
      ) : null}
    </div>
  );
};

export default HoleStats;

import { useRef } from "react";
import useIntersectionObserver from "../../../hooks/useIntersection";

const PlayerStat: React.FC<{
  startLegend?: string;
  endLegend?: string;
  statTitle: string;
  statValue: number;
  statVisualValue: number;
  statValueExtension: string;
}> = ({
  statTitle,
  statValue,
  statVisualValue,
  statValueExtension,
  startLegend = "",
  endLegend = "",
}) => {
  const ref = useRef<HTMLDivElement | any>();
  const intersectionEntry = useIntersectionObserver(ref, {
    threshold: 0.9,
    rootMargin: "80px",
    freezeOnceVisible: true,
  });
  const inViewport = intersectionEntry && intersectionEntry.isIntersecting;

  return (
    <div>
      <div
        ref={ref}
        className="flex flex-1 flex-col md:flex-row bg-white bg-opacity-10 p-2 items-start px-5 md:px-24 pt-4"
      >
        <div className="text-white text-xxs uppercase tracking-wider font-semibold pr-4 w-48">
          {statTitle}
        </div>
        <div className="flex flex-1 w-full items-start">
          <div className="flex flex-col flex-1 pt-2">
            <div className="flex flex-1 relative">
              <div
                className="h-2 z-20 relative bg-gradient-to-r from-grad_start to-grad_end transition-all duration-500 delay-100"
                style={{ width: inViewport ? statVisualValue + "%" : "0%" }}
              ></div>
              <div
                className="w-full absolute z-0 bg-dark_grey transition-all duration-300"
                style={{
                  height: 2,
                  top: 3,
                }}
              ></div>
            </div>
            <div className="flex flex-row justify-between text-xxs font-semibold text-white mt-1">
              <div>{startLegend}</div>
              <div>{endLegend}</div>
            </div>
          </div>
          <div className="flex flex-row justify-end items-end w-36">
            <div className="text-white font-normal text-3xl tracking-wider">
              {statValue}
            </div>
            <div className="text-white font-bold text-sm pb-2 pl-1 uppercase">
              {statValueExtension}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerStat;

import React, { useEffect, useRef, useState } from "react";
import { Route, useHistory, useLocation } from "react-router";
import { Switch } from "react-router-dom";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import { HoleProvider } from "../../context/holeContext";
import { HoleSpecsScroller } from "../elements/HoleSpecs";
import Ticker from "../elements/Ticker";
import Layout from "../layout/Layout";

import "react-spring-bottom-sheet/dist/style.css";
import useIsMobile from "../../hooks/useIsMobile";
import Course from "./Course";
import LeaderboardFull from "./LeaderboardFull";
import Player from "./Player";
import Livestream from "./Livestream";
import { SpringEvent } from "react-spring-bottom-sheet/dist/types";
import useGeoLivestream from "../../hooks/useGeoLivestream";
import CookieAcccept from "../shared/CookieAccept";
import Landing from "./Landing";
import { isAfterTournamentStart } from "../../misc/helpers";

type RoutesProps = {};

const SheetBottomButton: React.FC<{ onClick: any }> = ({
  onClick,
  children,
}) => {
  return (
    <div className="absolute z-50 md:hidden bottom-4 w-full flex flex-1 flex-row items-center justify-center">
      <button
        type="button"
        onClick={onClick}
        className="flex flex-row items-center justify-center self-center shadow-md bg-blue rounded-md p-2 w-36 ml-2"
      >
        <div className="text-white text-xs tracking-wider font-normal">
          {children}
        </div>
      </button>
    </div>
  );
};

const Routes: React.FC<RoutesProps> = () => {
  const { livestreamAvailable } = useGeoLivestream();

  const sheetRef = useRef<BottomSheetRef | null>(null);
  const [lockScroll, setLockScroll] = useState(false);
  const [showSheetCloseButton, setShowSheetCloseButton] = useState(false);
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/") {
      history.push("/leaderboard");
    }
  }, [location, history]);

  useEffect(() => {
    console.log(location.pathname);
    //ga('send', 'pageview');
  }, [location]);

  function closeSheet() {
    if (sheetRef.current) {
      sheetRef.current.snapTo(51);
      setShowSheetCloseButton(false);
    }
  }

  function setSheetFullscreen() {
    if (sheetRef.current) {
      sheetRef.current.snapTo(({ maxHeight }) => maxHeight);
      setLockScroll(true);
    }
  }

  function handleSpringEnd(event: SpringEvent) {
    if (!sheetRef.current) {
      return false;
    }

    if (event.type === "RESIZE" || event.type === "SNAP") {
      setLockScroll(sheetRef.current.height > 200);
      setShowSheetCloseButton(sheetRef.current.height > 200);
      // if (sheetRef.current.height < 150) {
      //   history.push("/" + location.pathname.split("/")[1]);
      // }
    }
  }

  function handleSpringStart(_: SpringEvent) {
    if (!sheetRef.current) {
      return false;
    }

    setShowSheetCloseButton(false);
  }

  function getSnapPoints({ maxHeight }: any) {
    if (location.pathname.includes("/hole")) {
      return [maxHeight, 51];
    }
    return [maxHeight, 182, 51];
  }

  let tournamentStarted = isAfterTournamentStart();

  return (
    <Layout>
      <Switch>
        {tournamentStarted ? (
          <Route path="/(leaderboard)">
            <LeaderboardFull />
          </Route>
        ) : (
          <Route path="/(leaderboard)">
            <Landing />
          </Route>
        )}
        <Route path="/players/:id">
          <Player />
        </Route>
        {isMobile ? (
          <>
            <Route path="/livestream">
              <Livestream />
              <BottomSheet
                ref={sheetRef}
                open={true}
                expandOnContentDrag={true}
                blocking={false}
                defaultSnap={({ maxHeight }) => 40}
                snapPoints={({ maxHeight }) => [maxHeight, 200, 51]}
                onSpringEnd={handleSpringEnd}
                onSpringStart={handleSpringStart}
                scrollLocking={lockScroll}
                className="z-30 absolute"
              >
                <Route path="/livestream/hole/:id">
                  <HoleProvider>
                    <HoleSpecsScroller handleCloseSheet={closeSheet} />
                  </HoleProvider>
                </Route>
                {livestreamAvailable ? (
                  <Route path="/livestream" exact>
                    <Ticker />
                    {showSheetCloseButton ? (
                      <SheetBottomButton onClick={closeSheet}>
                        Zum Livestream
                      </SheetBottomButton>
                    ) : null}
                  </Route>
                ) : null}
              </BottomSheet>
            </Route>
            <Route path="/course">
              <Course sheetFullscreen={setSheetFullscreen} />
              <BottomSheet
                ref={sheetRef}
                open={true}
                expandOnContentDrag={true}
                blocking={false}
                defaultSnap={({ maxHeight }) => 40}
                snapPoints={getSnapPoints}
                onSpringEnd={handleSpringEnd}
                onSpringStart={handleSpringStart}
                scrollLocking={lockScroll}
                className="z-30 absolute"
              >
                <Route path="*/hole/:id">
                  <HoleProvider>
                    <HoleSpecsScroller handleCloseSheet={closeSheet} />
                  </HoleProvider>
                </Route>
                <Route path="/course" exact>
                  <Ticker />
                  {showSheetCloseButton ? (
                    <SheetBottomButton onClick={closeSheet}>
                      Zur Kursübersicht
                    </SheetBottomButton>
                  ) : null}
                </Route>
              </BottomSheet>
            </Route>
          </>
        ) : (
          <>
            <Route path="/course">
              <Course sheetFullscreen={null} />
              <Route path="/course/hole/:id">
                <HoleProvider>
                  <HoleSpecsScroller />
                </HoleProvider>
              </Route>
              <Route path="/course" exact>
                <Ticker />
              </Route>
            </Route>
            {livestreamAvailable ? (
              <div id="stream-div">
                <Route path="/livestream">
                  <Livestream />
                  <Route path="/livestream/hole/:id">
                    <HoleProvider>
                      <HoleSpecsScroller />
                    </HoleProvider>
                  </Route>
                  <Route path="/livestream" exact>
                    <Ticker />
                  </Route>
                </Route>
              </div>
            ) : null}
          </>
        )}
      </Switch>
      <CookieAcccept />
    </Layout>
  );
};

export default Routes;

import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import api, { players_url, player_url } from "../../misc/api";
import { STALE_TIME } from "../../misc/config";
import {
  Player,
  PlayerSchema,
  PlayersListItem,
  PlayersListItemSchema,
} from "../../types/players";

async function fetchPlayers() {
  const { data }: AxiosResponse<{ players: PlayersListItem[] }> =
    await api.get(players_url);
  const safeParsedData = PlayersListItemSchema.parse(data);
  return safeParsedData;
}

async function fetchPlayer(id: number) {
  const { data }: AxiosResponse<Player> = await api.get(player_url(id));
  const safeParsedData = PlayerSchema.parse(data);
  return safeParsedData;
}

function usePlayers() {
  return useQuery(["players"], () => fetchPlayers());
}

export function usePlayer(id: number) {
  return useQuery(["player", id], () => fetchPlayer(id), {
    refetchInterval: STALE_TIME,
  });
}

export default usePlayers;

import i18n from "../../../misc/i18n";
import { getScoreInfo } from "./ScoreBox";

const ScorecardLegend: React.FC<{ textClass?: string }> = ({
  textClass = "text-white text-xxs",
}) => {
  const double = getScoreInfo(2);
  const bogey = getScoreInfo(1);
  const birdie = getScoreInfo(-1);
  const eagleBetter = getScoreInfo(-2);

  const outerClasses = "flex p-0.25 legend-sizes items-center justify-center";
  const innerClasses = "flex justify-center items-center w-full h-full";

  return (
    <div className="flex flex-row text-tiny justify-start items-center">
      <div className="flex flex-row items-center px-2">
        <div className={`${outerClasses} ${eagleBetter.style}`}>
          <div className={`${innerClasses} ${eagleBetter.bg}`}></div>
        </div>
        <div className={`${textClass} ml-2 font-normal`}>
          {i18n.hole.scoring.eagleBetter}
        </div>
      </div>

      <div className="flex flex-row items-center px-2">
        <div className={`${outerClasses} ${birdie.style}`}>
          <div className={`${innerClasses} ${birdie.bg}`}></div>
        </div>
        <div className={`${textClass} ml-2 font-normal`}>
          {i18n.hole.scoring.birdie}
        </div>
      </div>
      <div className="flex flex-row items-center px-2">
        <div className={`${outerClasses} ${bogey.style}`}>
          <div className={`${innerClasses} ${bogey.bg}`}></div>
        </div>
        <div className={`${textClass} ml-2 font-normal`}>
          {i18n.hole.scoring.bogey}
        </div>
      </div>
      <div className="flex flex-row items-center px-2">
        <div className={`${outerClasses} ${double.style}`}>
          <div className={`${innerClasses} ${double.bg}`}></div>
        </div>
        <div className={`${textClass} ml-2 font-normal`}>
          {i18n.hole.scoring.double}+
        </div>
      </div>
    </div>
  );
};

export default ScorecardLegend;

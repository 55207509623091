import { SplitStat } from "./HoleStatsSplit";

type ProgressBarSplitProps = {
  progress: SplitStat[];
  showText?: boolean;
  avgValue?: number | null;
};

const ProgressBarSplit: React.FC<ProgressBarSplitProps> = ({
  progress,
  showText,
  avgValue = null,
}) => {
  return (
    <div className="w-full">
      <div className="w-full bg-very_light_pink h-0.5 relative">
        <div
          className="bg-white_grey text-xs leading-none absolute h-1 text-center text-white"
          style={{
            left: 0,
            height: 2,
            top: 0,
            width: "100%",
          }}
        ></div>
        {!avgValue ? (
          <div
            className="bg-blue text-xs leading-none absolute h-1 text-center text-white"
            style={{
              width: progress[1].statValue + "%",
              top: -1,
              left: progress[0].statValue + "%",
            }}
          ></div>
        ) : (
          <div
            className="bg-blue text-xs leading-none absolute h-1 text-center text-white"
            style={{
              left: (avgValue / 4) * 100 - 5 + "%",
              top: -1,
              width: "10%",
            }}
          ></div>
        )}
      </div>
      <div className="flex flex-row justify-between pt-4">
        <div className="text-tiny text-middle_grey uppercase font-bold">
          {progress[0].statValue}
          {progress[0].statType} {progress[0].statTitle}
        </div>
        <div className="text-tiny text-blue uppercase font-bold">
          {progress[1].statValue}
          {progress[1].statType} {progress[1].statTitle}
        </div>
        <div className="text-tiny text-middle_grey uppercase font-bold">
          {progress[2].statValue}
          {progress[2].statType} {progress[2].statTitle}
        </div>
      </div>
    </div>
  );
};

export default ProgressBarSplit;

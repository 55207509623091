import { Transition } from "@headlessui/react";
import moment from "moment";
import React, { useState } from "react";
import { getFormattedScore, truncPlayerName } from "../../../misc/helpers";
import i18n from "../../../misc/i18n";
import {
  LeaderboardItem,
  LeaderboardPlayerStatus,
} from "../../../types/leaderboard";
import Avatar from "../../shared/Avatar";
import FavButton from "../../shared/FavButton";
import LeaderboardScorecard from "./LeaderboardScorecard";

type LeaderboardListItemProps = {
  player: LeaderboardItem;
  index: number;
  className?: string;
};

const LeaderboardListItem: React.FC<LeaderboardListItemProps> = ({
  player,
  className = "",
  index,
}) => {
  const [open, setOpen] = useState(false);
  // const { isMobile, innerWidth } = useIsMobile();

  function handleClick() {
    setOpen(!open);
  }

  const positionValue =
    player.status === LeaderboardPlayerStatus.Uncut
      ? player.rank
      : i18n.leaderboard.status[player.status];
  // let currentHole = getCurrentPlayerHole(
  //   player.holes_through,
  //   player.start_hole
  // );

  return (
    <>
      <Transition
        as="div"
        appear={true}
        show={true}
        enter={`duration-400 transform ease-in`}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        onClick={handleClick}
        className={`h-14 md:h-leaderboard-item-big md:min-h-leaderboard-item-big cursor-pointer ${className} border-0 border-transparent bg-white flex flex-1 flex-row items-center pl-3 pr-2 md:px-5
					${open ? "mb-0 border-b-0.5 border-light_grey" : "mb-2"}
				`}
        style={{
          transitionProperty: "opacity",
          transitionDelay: (index < 50 ? index * 50 : 50) + "ms",
          overflow: "hidden",
        }}
      >
        <div className="w-8 md:w-14" style={{ minWidth: 32 }}>
          <FavButton playerId={player.playerId} size={20} />
        </div>
        <div
          className="w-8 md:w-14 text-sm font-bold text-black_grey"
          style={{ minWidth: 32 }}
        >
          {positionValue}
        </div>
        <div className="flex md:flex-none md:w-64 flex-row">
          <div className="mr-4">
            <Avatar
              size={32}
              playerId={String(player.playerId)}
              playerName={`${player.firstName}_${player.lastName}`}
            />
          </div>
          <div className="flex flex-row items-center text-sm font-bold text-black_grey pr-2 capitalize">
            <div
              className="flex-row flex truncate overflow-ellipsis "
              style={{
                width: window.innerWidth - 280,
              }}
            >
              {truncPlayerName(player.firstName, player.lastName)}
              <div className="text-darkgrey">
                {player.startHole ? (player.startHole > 1 ? "*" : "") : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-row justify-end items-end">
          <div
            className="flex flex-grow flex-row justify-end items-center self-end"
            style={{
              maxWidth: 450,
              alignSelf: "flex-end",
            }}
          >
            <div className="hidden md:block md:flex-1 font-bold text-xxs text-left text-middle_grey">
              {player.country}
            </div>
            <div
              className={`hidden md:block md:flex-1 font-bold text-base text-left ${
                open ? "text-blue" : "text-dark_grey"
              }`}
            >
              {/* Number(player.toParToday) > 0 ? "+" : "" */}
              {player.toParToday !== null
                ? getFormattedScore(player.toParToday)
                : "-"}
            </div>
            {player.holesThrough !== null && player.holesThrough < 1 ? (
              <div className="w-10 md:flex-1 font-semibold text-base text-left text-black_grey whitespace-nowrap">
                <span className="text-xs font-semibold text-brown_grey">
                  {moment(player.teeTime).format("HH:mm")}
                </span>
              </div>
            ) : (
              <>
                <div className="w-10 md:flex-1 py-4 font-semibold text-base text-black_grey whitespace-nowrap">
                  {/* <span>{player.holes_through === 18 ? "F" : currentHole}</span> */}
                  <span>
                    {player.holesThrough !== null
                      ? player.holesThrough === 18
                        ? "F"
                        : player.holesThrough
                      : "-"}
                  </span>
                </div>
              </>
            )}
            <div
              className={`w-10 md:hidden py-4 pr-2 font-semibold text-base text-center text-black_grey whitespace-nowrap`}
            >
              <span>
                {/* Number(player.toParToday) > 0 ? "+" : "" */}
                {player.toParToday !== null || player.toParToday === "-"
                  ? getFormattedScore(player.toParToday)
                  : "-"}
              </span>
            </div>

            <div className="flex-1 font-semibold text-base text-center md:text-left text-black_grey">
              {player.toPar ?? "-"}
            </div>
            <div className="hidden md:block md:flex-1 font-semibold text-base text-left text-black_grey">
              {player.totalScoreToday ?? "-"}
            </div>
            {/* <div className="mr-2 xs:hidden justify-self-end">
            <img
              src={FLAG_URL}
              alt={player.country}
              title={player.country}
              className="self-end"
              style={{ width: 20 }}
            />
          </div> */}

            <div className="hidden md:block w-10 py-4 font-semibold text-black_grey text-base text-center whitespace-nowrap ">
              <span>{player.totalScore}</span>
            </div>
          </div>
        </div>
      </Transition>
      <div
        className={`${
          open
            ? `
                 shadow-2xl z-30 pb-6`
            : "h-0 overflow-hidden"
        } w-full transition-all ease-out bg-white`}
      >
        {open ? (
          <div>
            <LeaderboardScorecard player={player} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LeaderboardListItem;

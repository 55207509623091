import React from "react";
import useHole from "../../../hooks/queries/useHole";
import { yardsToMeters } from "../../../misc/helpers";
import i18n from "../../../misc/i18n";
import {
  tagsI18nStrings,
  TickerItem as TickerItemType,
} from "../../../types/ticker";
import Avatar from "../../shared/Avatar";
import useLeaderboard from "../../../hooks/queries/useLeaderboard";
import { Link, useRouteMatch } from "react-router-dom";

type TickerItemProps = {
  item: TickerItemType;
};

const TickerItem: React.FC<TickerItemProps> = ({ item }) => {
  const { data: hole } = useHole(item.hole);
  const { data: players } = useLeaderboard();
  const { path } = useRouteMatch();

  if (!hole) return null;

  const player = players
    ? players.find((p) => String(item.playerIds[0]) === String(p.playerId))
    : null;
  // const isHighlight = item.priority < 4;
  return (
    <div
      className={`w-full h-auto border-b-2 border-grey4 md:border-0 md:mb-2 px-6 py-3 pb-1 ${"text-darkgrey bg-white"}`}
    >
      <div className="flex flex-1 flex-row justify-between border-b-0.5 border-light_grey pb-2">
        <div className="flex flex-col">
          <div
            dangerouslySetInnerHTML={{ __html: item.headline }}
            className="font-semibold text-black_grey text-base pr-5"
          ></div>
          <div className="text-middle_grey text-xxs font-semibold capitalize">
            {tagsI18nStrings[item.type]}
          </div>
        </div>
        <Link to={`${path}/hole/${hole.holeNo}`}>
          <h3 className="font-normal text-black_grey text-2xl">
            {hole.holeNo}
          </h3>
          <div className="text-middle_grey_2 tracking-wider font-bold text-tiny uppercase ">
            Hole
          </div>
        </Link>
      </div>

      <Link to={`/players/${player?.playerId}`}>
        <div className="flex flex-1 flex-row items-center justify-between py-3">
          <div className="flex flex-row items-center">
            <Avatar
              size={36}
              playerId={player ? String(player.playerId) : null}
              playerName={
                player ? `${player.firstName}_${player.lastName}` : ""
              }
            />
            <div className="font-semibold text-black_grey text-sm ml-3">
              {player?.firstName} {player?.lastName}
            </div>
          </div>
          <div>
            <div className="text-middle_grey_2 tracking-wider font-bold text-tiny uppercase">
              {i18n.hole.par} {hole.par}
            </div>
            <div className="text-middle_grey_2 tracking-wider font-bold text-tiny uppercase">
              {yardsToMeters(hole.officialYardage)} {i18n.hole.metersShort}
            </div>
          </div>
        </div>
      </Link>

      {/* <div className="flex flex-row flex-1">
        <div className="flex-1 w-16 h-16 pt-3">
          <div
            className={`text-tiny ${
              isHighlight ? "text-white" : "text-brown_grey"
            } uppercase`}
          >
            {i18n.hole.par} {hole.par}
          </div>
          <div
            className={`text-tiny ${
              isHighlight ? "text-white" : "text-brown_grey"
            } uppercase`}
          >
            {yardsToMeters(hole.yardage)} {i18n.hole.metersShort}
          </div>
          <div>
            <div className="flex-1 text-3xl font-bold">{item.hole}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 pr-2">
          <div className="pb-6 text-2xl capitalize">
            {String(item.type).replace(/_/g, " ")}
          </div>
          <div
            className={`flex flex-row items-center flex-1 py-4 text-sm font-bold border-t border-b border-very_light_pink ${
              isHighlight ? "border-opacity-30" : ""
            }`}
          >
            <div className="mr-3">
              <Avatar
                size={36}
                playerName={
                  player ? `${player.first_name}_${player.last_name}` : ""
                }
              />
            </div>
            <div>{item.details}</div>
          </div>
          <div className="pt-7">
            <HeadlineSmall>
              <div dangerouslySetInnerHTML={{ __html: item.headline }}></div>
            </HeadlineSmall>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TickerItem;

import moment from "moment";

export const WEB_URL = "";
export const STALE_TIME = 30000;

export const BASE_TITLE = "BMW International Open";

export const AVATAR_BASE_URL = "/assets/images/220621_BIO22_Spieler_Digital";
export const holeGraphicUrl = (holeNumber: number) =>
  `/assets/course/BIO21_lochgrafiken/BIO21_lochgrafik_${holeNumber}.svg`;

export const PRIVACY_POLICY_LINK =
  "https://www.bmw-golfsport.com/en/footer/footer-section/privacy-policy.html";
export const IMPRESS_LINK =
  "https://www.bmw-golfsport.com/en/footer/metanavigation/legal-notice-pool/imprint.html";

export const TOURNAMENT_START_DATE = moment("04.07.2024", "DD.MM.YYYY")
  .hour(4)
  .minute(30);
export const TOURNAMENT_END_DATE = moment("07.07.2024", "DD.MM.YYYY");
export const LIVESTREAM_DATES_FORMAT = "DD.MM.YYYY HH:mm";

// Zeiten 2024 - stand 04.07.24
// 04.07.:                 12:30 Uhr – 18:00 Uhr
// 05.07.:                 12:30 Uhr – 18:00 Uhr
// 06.07.:                 09:30 Uhr – 16:00 Uhr
// 07.07.:                 13:00 Uhr – 18:00 Uhr

export const LIVESTREAM_DATES: {
  [key: number]: {
    start: string;
    end: string;
  };
} = {
  1: {
    start: `${TOURNAMENT_START_DATE.clone().format("DD.MM.YYYY")} 12:30`,
    end: `${TOURNAMENT_START_DATE.clone().format("DD.MM.YYYY")} 18:05`,
  },
  2: {
    start: `${TOURNAMENT_START_DATE.clone()
      .add(1, "days")
      .format("DD.MM.YYYY")} 12:30`,
    end: `${TOURNAMENT_START_DATE.clone()
      .add(1, "days")
      .format("DD.MM.YYYY")} 18:05`,
  },
  3: {
    start: `${TOURNAMENT_START_DATE.clone()
      .add(2, "days")
      .format("DD.MM.YYYY")} 09:30`,
    end: `${TOURNAMENT_START_DATE.clone()
      .add(2, "days")
      .format("DD.MM.YYYY")} 16:05`,
  },
  4: {
    start: `${TOURNAMENT_START_DATE.clone()
      .add(3, "days")
      .format("DD.MM.YYYY")} 13:00`,
    end: `${TOURNAMENT_START_DATE.clone()
      .add(3, "days")
      .format("DD.MM.YYYY")} 18:05`,
  },
};

export const VIMEO_URLS = {
  1: "https://vimeo.com/event/2229674/bcd53a82fd",
  2: "https://vimeo.com/event/2230002/d65e53191f",
  3: "https://vimeo.com/event/2230012/c89d3373fd",
  4: "https://vimeo.com/event/2230014/aca902b4ba",
};

export const SKY_LIVESTREAM_URL =
  "https://websitefreestreaming.akamaized.net/hls/live/2024637/hls-deevent-06/index.m3u8";

export const HOLE_VIDEO_IDS: number[] = [
  564559616, 564559894, 564560032, 564560218, 564560439, 564560620, 564560788,
  564560954, 564561115, 564561300, 564567676, 564567884, 564568116, 564568342,
  564568549, 564568747, 564568961, 564569149,
];

export const mostSearchedPlayerIds = [
  15608, 146950, 9828, 6156, 11086, 8978, 12412,
];

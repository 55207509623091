import React, { useContext, useEffect } from "react";
import { HoleContext } from "../../../context/holeContext";
import { ReactComponent as ArrowRight } from "./../../../assets/icons/icons-arrow.svg";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/icons-close.svg";
import { ReactComponent as ArrowLeft } from "./../../../assets/icons/icons-arrow-left.svg";
import useHole from "../../../hooks/queries/useHole";
import i18n from "../../../misc/i18n";
import HeadlineSmall from "../../shared/HeadlineSmall";
import HoleStats from "../../shared/HoleStats";
import HoleStatsSplit from "../../shared/HoleStatsSplit";
import Loading from "../../shared/Loading";
import SwipeableViews from "react-swipeable-views";
import useCourse from "../../../hooks/queries/useCourse";
import Error from "../../shared/Error";
import Flight from "./Flight";
import { ordinalSuffixOf, yardsToMeters } from "../../../misc/helpers";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import useIsMobile from "../../../hooks/useIsMobile";
import holeImages from "./holeImages";

type HoleProps = {
  asElement?: boolean;
  holeNumber: number | null;
  changeHole: any;
  active: boolean;
};

export const HoleSpecsScroller: React.FC<{ handleCloseSheet?: () => void }> = ({
  handleCloseSheet,
}) => {
  const { data, isLoading } = useCourse();
  const { currentHole, changeHole } = useContext(HoleContext);
  const { isMobile } = useIsMobile();
  const { url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      changeHole(Number(id));
    }
  }, [id, changeHole]);

  function handleIndexChange(index: number) {
    history.push(`/course/hole/${index + 1}`);
  }

  function handleChange(direction: "prev" | "next") {
    if (direction === "next") {
      history.push(`/course/hole/${currentHole === 18 ? 1 : currentHole + 1}`);
    } else {
      history.push(`/course/hole/${currentHole === 1 ? 18 : currentHole - 1}`);
    }
  }

  function handleClose() {
    if (handleCloseSheet) {
      handleCloseSheet();
    }
    history.push(`/${url.split("/")[1]}`);
  }

  const safeItems = [currentHole - 1, currentHole, currentHole + 1];

  return (
    <div
      style={{
        width: isMobile ? "100vw" : 375,
      }}
      className={`md:mr-9 md:float-right overflow-y-hidden overflow-x-hidden`}
    >
      <div className="flex flex-1 justify-center md:justify-end md:absolute md:top-52 md:right-9">
        <div className="flex w-36 h-0 md:h-8 bg-white items-center ">
          {isMobile ? (
            <button
              onClick={handleClose}
              className="px-4 text-middle_grey absolute right-0 top-2 z-10"
            >
              <div>
                <CloseIcon className="w-12 h-12" />
              </div>
            </button>
          ) : (
            <Link
              to={`/${url.split("/")[1]}`}
              className="flex flex-1 flex-row items-center px-4"
            >
              <div className="flex flex-1 text-middle_grey font-bold text-tiny tracking-wider uppercase">
                Zum Liveticker
              </div>
              <div>
                <div>
                  <ArrowRight />
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
      {!data || isLoading ? (
        <div
          style={{
            width: isMobile ? "100vw" : 375,
          }}
          className="bg-white w-full relative md:absolute md:bottom-6 top-4 md:top-60 xs:px-0 sm:px-0 md:right-9 overflow-x-hidden"
        >
          <Loading />
        </div>
      ) : (
        <SwipeableViews
          key={currentHole}
          axis="x"
          index={currentHole - 1}
          onChangeIndex={handleIndexChange}
          className="relative md:absolute md:bottom-6 top-4 md:top-60 xs:px-0 sm:px-0 md:right-9 overflow-x-hidden overflow-y-auto"
          style={{
            width: isMobile ? "100vw" : 375,
          }}
        >
          {data.map((h) => {
            const isLoadable = safeItems.includes(h.holeNo);
            return (
              <div
                hidden={currentHole !== h.holeNo && !isMobile}
                key={h.holeNo}
              >
                <HoleSpecs
                  key={h.holeNo}
                  holeNumber={isLoadable ? h.holeNo : null}
                  active={currentHole === h.holeNo}
                  changeHole={handleChange}
                />
              </div>
            );
          })}
        </SwipeableViews>
      )}
    </div>
  );
};

const HoleSpecs: React.FC<HoleProps> = ({
  asElement = false,
  active,
  holeNumber,
  changeHole,
}) => {
  const { data: holeData, isLoading, isError } = useHole(holeNumber);

  if (isLoading && !isError && !holeData) {
    return (
      <div className="pb-6 bg-white overflow-x-hidden ">
        <Loading />
      </div>
    );
  }

  if ((isError || !holeData) && !isLoading) {
    return (
      <div className="pb-6 bg-white overflow-x-hidden ">
        <Error />
      </div>
    );
  }

  if (holeData) {
    return (
      <div className="pb-6 bg-white overflow-hidden " key={holeData.holeNo}>
        <div className="flex flex-row h-10 mt-1 justify-center">
          <button
            type="button"
            onClick={() => changeHole("prev")}
            className={`flex w-10 h-10 mr-2 items-center justify-center text-middle_grey hover:text-dark_grey ${
              false ? "opacity-50" : ""
            }`}
          >
            <ArrowLeft />
          </button>
          <button
            type="button"
            onClick={() => changeHole("next")}
            className={`flex w-10 h-10 items-center justify-center text-middle_grey hover:text-dark_grey ${
              false ? "opacity-50" : ""
            }`}
          >
            <ArrowLeft className="transform rotate-180" />
          </button>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-col justify-start pl-8">
            <div className="pb-10 text-left">
              <h2 className="text-6xl font-semibold">{holeData.holeNo}</h2>
            </div>
            <div className="pb-6">
              <div className="uppercase text-tiny font-bold text-middle_grey_2 tracking-wider">
                {i18n.hole.par}
              </div>
              <h3 className="font-normal text-28 pt-1 text-black_grey tracking-wider">
                {holeData.par}
              </h3>
            </div>
            <div className="pb-6 ">
              <div className="uppercase text-tiny font-bold text-middle_grey_2 tracking-wider">
                {i18n.hole.meters}
              </div>
              <h4 className="font-normal text-28 pt-1 text-black_grey tracking-wider">
                {yardsToMeters(holeData.officialYardage)}
              </h4>
            </div>
            <div className="pb-2">
              <div
                title={i18n.hole.strokeIndex}
                className="uppercase text-tiny font-bold text-middle_grey_2 tracking-wider"
              >
                {i18n.hole.strokeIndexShort}
              </div>
              <h4 className="font-normal text-28 pt-1 text-black_grey tracking-wider">
                {holeData.difficulty || "-"}
              </h4>
            </div>
          </div>
          {active ? (
            <div className="flex items-center flex-1 px-6 pt-14">
              {/* @ts-ignore */}
              {holeImages[String(holeNumber || 1)]}
            </div>
          ) : null}
        </div>
        <div className="px-6 pt-6">
          <HeadlineSmall>{i18n.hole.hitsInReg}</HeadlineSmall>
          <div className="flex flex-row items-baseline justify-between pt-1">
            <div className="flex-1 mr-6">
              <HoleStats
                statTitle={i18n.hole.greens}
                statType="%"
                statValue={String(holeData.greensHit)}
              />
            </div>
            {holeData.par !== 3 ? (
              <div className="flex-1">
                <HoleStats
                  statTitle={i18n.hole.fairways}
                  statType="%"
                  statValue={String(holeData.fairwaysHit)}
                />
              </div>
            ) : null}
          </div>
        </div>
        {holeData.playersOnHole.length > 0 ? (
          <div className="px-6 pt-4">
            {holeData.playersOnHole.map((p, i) => (
              <Flight
                key={`flight_${i}`}
                flight={p}
                flightNumber={i + 1}
                holeNumber={holeData.holeNo}
              />
            ))}
          </div>
        ) : null}
        <div className="px-6 pt-9">
          <div className="flex flex-row items-baseline justify-between pt-4">
            <HoleStatsSplit
              statTitle={i18n.hole.avgScore}
              statValues={[
                {
                  statTitle: i18n.hole.under,
                  statType: "%",
                  statValue: holeData.scoreBreakdown.under,
                },
                {
                  statTitle: i18n.hole.par,
                  statType: "%",
                  statValue: holeData.scoreBreakdown.par,
                },
                {
                  statTitle: i18n.hole.over,
                  statType: "%",
                  statValue: holeData.scoreBreakdown.above,
                },
              ]}
              statMainValue={String(holeData.scoringAverage)}
            />
          </div>
        </div>
        <div className="px-6 pt-7">
          <div className="flex flex-row items-baseline justify-between pt-4">
            <HoleStatsSplit
              statTitle={i18n.hole.avgPutts}
              avg
              statValues={[
                {
                  statTitle: "",
                  statType: "<",
                  statValue: 0,
                },
                {
                  statTitle: "",
                  statType: "",
                  statValue: 2,
                },
                {
                  statTitle: "",
                  statType: ">",
                  statValue: 2,
                },
              ]}
              statMainValue={String((holeData.averagePutts ?? 0).toFixed(2))}
            />
          </div>
        </div>
        <div className="px-6 pt-12">
          <HeadlineSmall>{i18n.hole.hitsInRegCompared}</HeadlineSmall>
          <div className="flex flex-row items-baseline justify-between pt-1">
            {holeData.greensHitRank !== null ? (
              <div className="flex-1 mr-6">
                <HoleStats
                  subTitle={i18n.hole.hardestToHit}
                  statTitle={i18n.hole.greens}
                  statType={ordinalSuffixOf(
                    holeData.greensHitRank,
                  ).toUpperCase()}
                  statValue={holeData.greensHitRank}
                  progressValue={(holeData.greensHitRank / 18) * 100}
                />
              </div>
            ) : null}
            {holeData.par !== 3 && holeData.fairwaysHitRank !== null ? (
              <div className="flex-1">
                <HoleStats
                  subTitle={i18n.hole.hardestToHit}
                  statTitle={i18n.hole.fairways}
                  statType={ordinalSuffixOf(
                    holeData.fairwaysHitRank,
                  ).toUpperCase()}
                  statValue={holeData.fairwaysHitRank}
                  progressValue={(holeData.fairwaysHitRank / 18) * 100}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default HoleSpecs;

import { useState } from "react";
import useCourse from "../../../hooks/queries/useCourse";
import i18n from "../../../misc/i18n";
import {
  Player,
  PlayerScorecard,
  PlayerScorecardHole,
} from "../../../types/players";
import ScoreBox from "./ScoreBox";
import Scorecard from "./Scorecard";

type ExtendedScorecard = {
  round: string;
  holes: PlayerScorecardHole[];
};

const PlayerScorecardMobile: React.FC<{
  scoreboard: ExtendedScorecard;
  index: number;
  baseScorecard: PlayerScorecard;
}> = ({ scoreboard, index, baseScorecard }) => {
  const [open, setOpen] = useState(false);

  const playedHoles = scoreboard.holes.filter((h) => h.holeStrokes !== "0");
  const through = playedHoles.length;
  const total = scoreboard.holes
    .filter((h) => h.holeStrokes !== "0" && h.holeStrokes !== "-")
    .map((h) => Number(h.holeStrokes))
    .reduce((a, b) => a + b, 0);

  const relativeScore = playedHoles
    .filter((h) => h.holeStrokes !== "0" && h.holeStrokes !== "-")
    .map((h) => Number(h.holeStrokes) - h.holePar)
    .reduce((a, b) => a + b, 0);

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className={`flex flex-1 flex-row bg-white border-b-0.5 border-light_grey h-12 items-center
					cursor-pointer
					`}
      >
        <div className="pl-4 flex-1 font-bold text-sm tracking-wider">
          {i18n.leaderboard.round} {index + 1}
        </div>
        <div className="px-4 font-normal text-sm tracking-wider w-14 text-left">
          {through === 18 ? "F" : through}
        </div>
        <div className="px-4 font-normal text-sm tracking-wider w-14 text-left">
          {total}
        </div>
        <div className="px-4 font-bold text-blue text-sm tracking-wider w-14 text-left">
          {relativeScore === 0
            ? "E"
            : relativeScore > 0
              ? "+" + relativeScore
              : relativeScore}
        </div>
      </div>
      <div
        className={`
			overflow-hidden transition-all bg-white w-full
			${open ? "h-60" : "h-0"}
			`}
      >
        <div className="px-5 py-5">
          {open ? <Scorecard scorecard={baseScorecard} /> : null}
        </div>
      </div>
    </>
  );
};

const PlayerScorecardFull: React.FC<{
  scoreboard: ExtendedScorecard;
  index: number;
  baseScorecard: PlayerScorecard;
}> = ({ scoreboard, index, baseScorecard }) => {
  const { data } = useCourse();

  const total = scoreboard.holes
    .filter((h) => h.holeStrokes !== "0" && h.holeStrokes !== "-")
    .map((h) => Number(h.holeStrokes))
    .reduce((a, b) => a + b, 0);
  const relativeScore = scoreboard.holes
    .filter((h) => h.holeStrokes !== "0" && h.holeStrokes !== "-")
    .map((h) => Number(h.holeStrokes) - h.holePar)
    .reduce((a, b) => a + b, 0);

  if (!data || isNaN(total)) {
    return null;
  }

  return (
    <>
      <div
        className={`flex flex-1 flex-row bg-white border-b-0.5 border-light_grey h-12 items-center md:mx-24`}
      >
        <div
          className="flex flex-row mr-12"
          style={{
            minWidth: 150,
          }}
        >
          <div className="pl-4 font-bold text-sm tracking-wider">
            {i18n.leaderboard.round} {index + 1}
          </div>
          <div className="px-4 font-normal text-sm tracking-wider w-14 text-left"></div>
        </div>
        <div className="flex flex-1 flex-row">
          {data.map((h, i) => {
            const score =
              scoreboard.holes.find((s) => s.hole === h.holeNo) || null;
            return (
              <div
                key={`${score?.holeStrokes}_${i}_hscore`}
                className="xl:flex-1 lg:w-8 xl:w-auto justify-center flex"
                style={{ maxWidth: 47.7 }}
              >
                {score &&
                score.holeStrokes !== "-" &&
                score.holeStrokes !== "0" ? (
                  <ScoreBox score={Number(score.holeStrokes)} hole={h} />
                ) : (
                  "-"
                )}
              </div>
            );
          })}
          <div className="flex flex-row flex-1 justify-end lg:justify-center">
            <div className="px-4 font-semibold text-sm tracking-wider w-16 text-left">
              {total}
            </div>
            <div className="px-4 font-bold text-blue text-sm tracking-wider w-16 text-left">
              {relativeScore === 0
                ? "E"
                : relativeScore > 0
                  ? "+" + relativeScore
                  : relativeScore}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ScorecardHeaderFull: React.FC<{}> = () => {
  const course = useCourse();

  if (!course.data) {
    return null;
  }

  return (
    <>
      <div className="flex flex-1 flex-row uppercase text-middle_grey my-4 md:mx-24">
        <div
          className="pl-4 text-tiny font-bold tracking-wider"
          style={{
            minWidth: 150,
          }}
        ></div>
        <div className="px-4 text-tiny font-bold tracking-wider w-12">
          {i18n.leaderboard.hole}
        </div>
        {course.data.map((h) => {
          return (
            <div
              key={h.holeNo}
              className="px-4 text-tiny font-bold tracking-wider w-8 lg:w-8 xl:w-12"
            >
              {h.holeNo}
            </div>
          );
        })}
        <div className="flex flex-row flex-1 justify-end lg:justify-center">
          <div className="px-4 text-tiny font-bold tracking-wider w-16"></div>
          <div className="px-4 text-tiny font-bold tracking-wider w-16"></div>
        </div>
      </div>

      <div className="flex flex-1 flex-row uppercase text-middle_grey my-4 md:mx-24">
        <div
          className="pl-4 text-tiny font-bold tracking-wider"
          style={{
            minWidth: 150,
          }}
        >
          {i18n.leaderboard.round}
        </div>
        <div className="px-4 text-tiny font-bold tracking-wider w-12">
          {i18n.hole.par}
        </div>
        {course.data.map((h) => {
          return (
            <div
              key={`${h.holeNo}_par`}
              className="px-4 text-tiny font-bold tracking-wider w-8 lg:w-8 xl:w-12"
            >
              {h.par}
            </div>
          );
        })}
        <div className="flex flex-row flex-1 justify-end lg:justify-center">
          <div className="px-4 text-tiny font-bold tracking-wider w-16">
            {i18n.leaderboard.totalScore}
          </div>
          <div className="px-4 text-tiny font-bold tracking-wider w-16">
            {i18n.leaderboard.score}
          </div>
        </div>
      </div>
    </>
  );
};

const PlayerDetailScoreboards: React.FC<{
  player: Player;
  currentHole: number | null;
  isMobile: boolean;
}> = ({ player, currentHole, isMobile }) => {
  const scoreboards = Object.entries(player.scorecard).map(([key, value]) => ({
    holes: Object.entries(value).map(([key2, hole]) => hole),
    round: key,
  }));

  return (
    <div>
      {isMobile ? (
        <div className="flex flex-1 flex-row uppercase text-middle_grey my-4">
          <div className="pl-4 text-tiny flex-1 font-bold tracking-wider">
            {i18n.leaderboard.round}
          </div>
          <div className="px-4 text-tiny font-bold tracking-wider">
            {i18n.hole.par}
          </div>
        </div>
      ) : (
        <ScorecardHeaderFull />
      )}

      {scoreboards.map((scoreboard, index) =>
        isMobile ? (
          <PlayerScorecardMobile
            key={`sc_${index}`}
            scoreboard={scoreboard as ExtendedScorecard}
            // @ts-ignore
            baseScorecard={player.scorecard[scoreboard.round]}
            index={index}
          />
        ) : (
          <PlayerScorecardFull
            key={`sc_${index}`}
            scoreboard={scoreboard as ExtendedScorecard}
            // @ts-ignore
            baseScorecard={player.scorecard[scoreboard.round]}
            index={index}
          />
        ),
      )}
    </div>
  );
};

export default PlayerDetailScoreboards;

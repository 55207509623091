import { z } from "zod";

export enum RelativeScore {
  albatross = "albatross",
  eagle = "eagle",
  birdie = "birdie",
  par = "par",
  bogey = "bogey",
  double_bogey = "double_bogey",
  tripple_bogey = "tripple_bogey",
  quadruple_bogey = "quadruple_bogey",
}

export const PlayersListItemSchema = z.object({
  playerId: z.number().int().catch(0),
  firstName: z.string().catch(""),
  lastName: z.string().catch(""),
  country: z.string().catch(""),
  isAmateur: z.boolean().catch(false),
});

export const PlayerScorecardHoleSchema = z.object({
  hole: z.number().int().catch(0),
  holePar: z.number().int().catch(0),
  holeStrokes: z
    .string()
    .transform((val) => (val === "-" ? "0" : val))
    .catch("0"),
  holeScore: z.string().catch(""),
});

export const PlayerStatsSchema = z.object({
  gir: z.number().min(0).max(100).catch(0),
  girRank: z.number().int().catch(0),
  fairwayAccuracy: z.number().min(0).max(100).catch(0),
  fairwayAccuracyRank: z.number().int().catch(0),
  longestDrive: z.number().min(0).catch(0),
  longestDriveRank: z.number().int().catch(0),
  puttingAverage: z.number().catch(0),
  puttingAverageRank: z.number().int().catch(0),
  birdieHitRate: z.number().min(0).max(100).catch(0),
  birdieHitRateRank: z.number().int().catch(0),
});

export const PlayerProfileSchema = PlayersListItemSchema.and(
  z.object({
    rank: z.string().catch("0"),
    previousRank: z.string().nullable().catch(null),
    toPar: z.string().catch(""),
    toParToday: z.string().catch(""),
  }),
);

export const PlayerScorecardSchema = z
  .object({
    r1: z.array(PlayerScorecardHoleSchema),
    r2: z.array(PlayerScorecardHoleSchema),
    r3: z.array(PlayerScorecardHoleSchema),
    r4: z.array(PlayerScorecardHoleSchema),
  })
  .partial()
  .refine((value) => {
    // Custom validation logic to filter out elements where holeStrokes === "0"
    const filteredScorecard = {} as any;
    if (value.r1)
      filteredScorecard.r1 = value.r1.filter(
        (hole) => hole.holeStrokes !== "0",
      );
    if (value.r2)
      filteredScorecard.r2 = value.r2.filter(
        (hole) => hole.holeStrokes !== "0",
      );
    if (value.r3)
      filteredScorecard.r3 = value.r3.filter(
        (hole) => hole.holeStrokes !== "0",
      );
    if (value.r4)
      filteredScorecard.r4 = value.r4.filter(
        (hole) => hole.holeStrokes !== "0",
      );
    return filteredScorecard;
  });

export const PlayerSchema = z.object({
  profile: PlayerProfileSchema,
  scorecard: PlayerScorecardSchema, // Set params to false to suppress unused parameter warning
  stats: PlayerStatsSchema,
});

export type Player = z.infer<typeof PlayerSchema>;
export type PlayerStats = z.infer<typeof PlayerStatsSchema>;
export type PlayerProfile = z.infer<typeof PlayerProfileSchema>;
export type PlayersListItem = z.infer<typeof PlayersListItemSchema>;
export type PlayerScorecardHole = z.infer<typeof PlayerScorecardHoleSchema>;
export type PlayerScorecard = z.infer<typeof PlayerScorecardHoleSchema>[];

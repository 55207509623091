import { AxiosResponse } from "axios";
import moment from "moment";
import { useQuery } from "react-query";
import api, { news_url } from "../../misc/api";
import { TickerItem } from "../../types/ticker";

function sortByDate(list: TickerItem[]) {
  return list.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
}

async function fetchTicker(startAt: number | null) {
  const { data }: AxiosResponse<{ news: TickerItem[] }> = await api.get(
    news_url(startAt),
  );
  return sortByDate(data.news);
}

function useTicker(startAt: number | null) {
  return useQuery(["ticker"], () => fetchTicker(startAt), {
    refetchInterval: 30000,
  });
}

export default useTicker;

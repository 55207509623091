import * as React from "react";
import useLeaderboard from "../hooks/queries/useLeaderboard";
import { getCurrentPlayerHole } from "../misc/helpers";

type ContextState = {
  currentHole: number;
  changeHole: (holeNumber: number) => void;
};

const HoleContext = React.createContext<ContextState>({} as ContextState);

const HoleProvider: React.FC<{}> = ({ children }) => {
  const [autoupdate, setAutoupdate] = React.useState(false);
  const { data: leaderboard } = useLeaderboard();
  const [currentHole, setCurrentHole] = React.useState(1);

  React.useEffect(() => {
    if (leaderboard && autoupdate) {
      const bestActivePlayer = leaderboard.find(
        (leaderboardItem) =>
          leaderboardItem.holesThrough ||
          (0 < 18 &&
            leaderboardItem.holesThrough !== 0 &&
            leaderboardItem.status === "Uncut"),
      );

      if (bestActivePlayer) {
        // select holes_through + 1,
        // this will show the hole the player is currently on
        const leaderHole =
          Number(
            getCurrentPlayerHole(
              bestActivePlayer.holesThrough || 0,
              bestActivePlayer.startHole || 0,
            ),
          ) + 1;

        setCurrentHole(leaderHole < 19 ? leaderHole : 1);
      }
    }
  }, [leaderboard, autoupdate]);

  function changeHole(hole: number) {
    setAutoupdate(false);
    setCurrentHole(hole);
  }

  const value = {
    currentHole,
    changeHole,
  };
  return <HoleContext.Provider value={value}>{children}</HoleContext.Provider>;
};

export { HoleProvider, HoleContext };

import moment from "moment";
import useCountdown from "../../hooks/useCountdown";
import { LIVESTREAM_DATES, LIVESTREAM_DATES_FORMAT } from "../../misc/config";
import { isTournamentOver, tournamentDayIndex } from "../../misc/helpers";
import i18n from "../../misc/i18n";

// const Title: React.FC<{ isLive: boolean; currentDay: Moment }> = ({
//   isLive,
//   currentDay,
// }) => {
//   return (
//     <div className="w-full text-center mb-20">
//       <h1 className="text-darkgrey text-5xl uppercase">{i18n.title.bmwi}</h1>
//       {!isLive ? (
//         <h2 className="text-darkgrey text-2xl mt-3 uppercase">
//           {i18n.countdown.nextLiveStream} - DAY{" "}
//           {getCurrentTournamentRound() + 1}
//         </h2>
//       ) : (
//         <h2 className="text-darkgrey text-2xl mt-3 uppercase">
//           {currentDay.format("DD. MMM")} - DAY {getCurrentTournamentRound()}
//         </h2>
//       )}
//     </div>
//   );
// };

function formatNumber(num: number) {
  if (num < 0) return "00";
  if (num < 10) {
    return `0${num}`;
  }
  return num;
}

type CountdownProps = { small?: boolean; isMobile?: boolean };

const Countdown: React.FC<CountdownProps> = ({ small, isMobile }) => {
  const currentRound = tournamentDayIndex() + 1;

  const tournamentOver = isTournamentOver();
  const startDate = LIVESTREAM_DATES[currentRound || 1].start;
  const endDate = LIVESTREAM_DATES[currentRound || 1].end;
  const nextStart =
    currentRound < 4 && !tournamentOver
      ? LIVESTREAM_DATES[currentRound + 1].start
      : null;

  const isLive = moment().isSameOrBefore(
    moment(endDate, LIVESTREAM_DATES_FORMAT),
  );

  const { timeLeft } = useCountdown({
    finishDate: !isLive && nextStart ? nextStart : startDate,
  });

  return (
    <div
      className={`text-white`}
      style={
        small && !isMobile
          ? {
              transform: `scale(${window.innerHeight > 900 ? 0.3 : 0.2})`,
            }
          : {}
      }
    >
      <div className="flex flex-1 flex-row justify-center">
        {timeLeft.days !== 0 ? (
          <>
            <div className="md:w-28 text-center flex flex-col items-center">
              <div className="text-darkgrey text-4xl md:text-8xl md:mb-8">
                {formatNumber(timeLeft.days)}
              </div>
              <div>
                <span className="text-xs md:text-xl text-brown_grey uppercase">
                  {i18n.countdown.days}
                </span>
              </div>
            </div>
            <div>
              <span className="text-darkgrey text-4xl md:text-8xl md:px-3 px-2">
                :
              </span>
            </div>
          </>
        ) : null}
        <div className="md:w-28 text-center flex flex-col items-center">
          <div className="text-darkgrey text-4xl md:text-8xl md:mb-8">
            {formatNumber(timeLeft.hours)}
          </div>
          <div>
            <span className="text-xs md:text-xl text-brown_grey uppercase">
              {i18n.countdown.hours}
            </span>
          </div>
        </div>

        <div>
          <span className="text-darkgrey text-4xl md:text-8xl md:px-3 px-2">
            :
          </span>
        </div>

        <div className="md:w-28 text-center flex flex-col items-center">
          <div className="text-darkgrey text-4xl md:text-8xl md:mb-8 ">
            {formatNumber(timeLeft.minutes)}
          </div>
          <div>
            <span className="text-xs md:text-xl text-brown_grey uppercase">
              {i18n.countdown.minutes}
            </span>
          </div>
        </div>

        <div>
          <span className="text-darkgrey text-4xl md:text-8xl md:px-3 px-2">
            :
          </span>
        </div>
        <div className="md:w-28 text-center flex flex-col items-center">
          <div className="text-darkgrey text-4xl md:text-8xl md:mb-8">
            {formatNumber(timeLeft.seconds)}
          </div>
          <div>
            <span className="text-xs md:text-xl text-brown_grey uppercase">
              {i18n.countdown.seconds}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Countdown;

import { z } from "zod";

export const ScoreBreakdownSchema = z.object({
  under: z.number().catch(0),
  par: z.number().catch(0),
  above: z.number().catch(0),
});

export const HolePairingSchema = z.object({
  playerId: z.number().int().catch(0),
  firstName: z.string().catch(""),
  lastName: z.string().catch(""),
  country: z.string().catch(""),
  rank: z.string().catch(""),
  holesThrough: z.number().int().catch(0),
  total: z.number().catch(0),
  toPar: z.string().catch(""),
});

export const HoleSchema = z.object({
  scoreBreakdown: ScoreBreakdownSchema,
  playersOnHole: z.array(z.array(HolePairingSchema)),
  difficulty: z.number().nullable().catch(null),
  scoringAverage: z.number().nullable().catch(null),
  scoringAverageRank: z.number().nullable().catch(null),
  averagePutts: z.number().nullable().catch(null),
  averagePuttsRank: z.number().nullable().catch(null),
  timesPlayed: z.number().nullable().catch(null),
  fairwaysHit: z.number().nullable().catch(null),
  fairwaysHitRank: z.number().nullable().catch(null),
  greensHit: z.number().nullable().catch(null),
  greensHitRank: z.number().nullable().catch(null),
  holeNo: z.number().int().catch(0),
  officialYardage: z.number().int().catch(0),
  par: z.number().int().catch(0),
});

export type ScoreBreakdown = z.infer<typeof ScoreBreakdownSchema>;
export type HolePairing = z.infer<typeof HolePairingSchema>;
export type Hole = z.infer<typeof HoleSchema>;

import React from "react";
import useCourse from "../../../hooks/queries/useCourse";
import useScorecardValues from "../../../hooks/useScorecardValues";
import { getFormattedScore } from "../../../misc/helpers";
import { CourseHole } from "../../../types/course";
import { PlayerScorecard, PlayerScorecardHole } from "../../../types/players";
import ScoreBox from "../Player/ScoreBox";

type ScorecardProps = {
  scorecards: PlayerScorecard[] | null;
  hideHeader?: boolean;
};

const ColTitle: React.FC<{}> = ({ children }) => {
  return (
    <div className="uppercase" style={{ minWidth: 40 }}>
      {children}
    </div>
  );
};

const colWidth = "w-9";

const Score: React.FC<{
  scoreItem: PlayerScorecardHole | null;
}> = ({ scoreItem }) => {
  return (
    <div
      className={`flex flex-grow ${colWidth} h-6 rounded-full justify-center items-center`}
    >
      <div className="text-black_grey text-base font-semibold">
        {scoreItem ? (
          <ScoreBox
            score={Number(scoreItem.holeStrokes)}
            hole={{
              par: scoreItem.holePar,
              holeNo: scoreItem.hole,
            }}
          />
        ) : (
          "-"
        )}
      </div>
    </div>
  );
};

const HeadHole: React.FC<{ hole: CourseHole }> = ({ hole }) => {
  return (
    <div className={`flex flex-grow ${colWidth} h-6 justify-center`}>
      <div className="text-black_grey flex justify-center items-center text-xxs font-bold bg-white_grey w-6 h-6 rounded-full">
        {hole.holeNo}
      </div>
    </div>
  );
};

const ScorecardRow: React.FC<{
  scorecard: PlayerScorecard;
  scorecardIndex: number;
  holes: CourseHole[];
}> = ({ scorecard, scorecardIndex, holes }) => {
  const { inScore, outScore, toParScore } = useScorecardValues({
    holes,
    scorecard,
  });

  if (toParScore == null) {
    return null;
  }

  return (
    <div className="flex flex-row flex-grow items-center justify-between mt-4">
      <ColTitle>R{scorecardIndex + 1}</ColTitle>
      {holes.slice(0, 9).map((hole) => {
        const score = scorecard.find((s) => s.hole === hole.holeNo) || null;
        return (
          <Score
            key={`sc_score_fn_${hole.holeNo}_${scorecardIndex}`}
            scoreItem={score}
          />
        );
      })}
      <div className="flex h-6 mx-2 w-9 rounded-full justify-center items-center">
        <div className="text-black_grey text-base font-semibold">
          {scorecard[8].holeStrokes !== "0" && inScore ? inScore : ""}
        </div>
      </div>
      {holes.slice(9, 18).map((hole) => {
        const score = scorecard.find((s) => s.hole === hole.holeNo) || null;
        return (
          <Score
            key={`sc_score_bn_${hole.holeNo}_${scorecardIndex}`}
            scoreItem={score}
          />
        );
      })}
      <div className="flex h-6 w-9 mx-2 rounded-full justify-center items-center">
        <div className="text-black_grey text-base font-semibold">
          {scorecard[17].holeStrokes !== "0" && outScore ? outScore : ""}
        </div>
      </div>
      <div className="flex w-14 h-6 justify-center items-center">
        <div className="text-black_grey text-base font-semibold">
          {inScore !== null && outScore !== null ? (
            <>{inScore + outScore || ""}</>
          ) : null}
        </div>
      </div>
      <div className="flex w-14 h-6 justify-center items-center">
        <div className="text-black_grey text-base font-bold">
          {toParScore > 0 ? "+" : ""}
          {toParScore !== null ? getFormattedScore(toParScore) : ""}
        </div>
      </div>
    </div>
  );
};

const ScoreBackgroundCol = ({ roundsPlayed }: { roundsPlayed: number }) => {
  let height = roundsPlayed * 41 + 82;
  if (roundsPlayed > 0) {
    height = height + 10;
  }
  return (
    <div
      className="w-9 absolute bg-white_grey rounded-full"
      style={{
        top: -5,
        height,
        zIndex: -1,
      }}
    ></div>
  );
};

const ScorecardAllRounds: React.FC<ScorecardProps> = ({ scorecards }) => {
  const { data: holes } = useCourse();

  const { inCount, outCount } = useScorecardValues({
    holes,
    scorecard: null,
  });

  if (!holes) return null;

  return (
    <div className="" style={{ maxWidth: 2000 }}>
      <div className="flex flex-row items-center justify-between">
        <ColTitle>HOLE</ColTitle>
        {holes.slice(0, 9).map((hole) => (
          <HeadHole key={`sc_hole_${hole.holeNo}`} hole={hole} />
        ))}
        <div className="flex h-6 w-9 mx-2 relative rounded-full lg:justify-center items-center">
          <ScoreBackgroundCol roundsPlayed={scorecards?.length || 0} />
          <div className="text-black_grey text-xxs font-semibold">In</div>
        </div>
        {holes.slice(9, 18).map((hole) => (
          <HeadHole key={`sc_hole_${hole.holeNo}`} hole={hole} />
        ))}
        <div className="hidden relative md:flex w-9 mx-2 h-6 rounded-full justify-center items-center">
          <ScoreBackgroundCol roundsPlayed={scorecards?.length || 0} />
          <div className="text-black_grey text-xxs font-semibold">Out</div>
        </div>
        <div className="hidden w-14 md:flex h-6 justify-center items-center">
          <div className="text-middle_grey font-bold text-xxs">total</div>
        </div>
        <div className="hidden w-14 md:flex h-6 justify-center items-center">
          <div className="text-middle_grey font-bold text-xxs">To Par</div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-between mt-4 mb-8">
        <ColTitle>PAR</ColTitle>
        {holes.slice(0, 9).map((hole) => (
          <div
            key={`sc_hole_${hole.holeNo}`}
            className={`flex flex-grow ${colWidth} h-6 rounded-full justify-center items-center`}
          >
            <div className="text-black_grey text-base font-semibold">
              {hole.par}
            </div>
          </div>
        ))}
        <div className="flex h-6 w-9 mx-2 rounded-full justify-center items-center">
          <div className="text-black_grey text-base font-semibold">
            {inCount}
          </div>
        </div>
        {holes.slice(9, 18).map((hole) => (
          <div
            key={`sc_hole_${hole.holeNo}`}
            className={`flex flex-grow ${colWidth} h-6 rounded-full justify-center items-center`}
          >
            <div className="text-black_grey text-base font-semibold">
              {hole.par}
            </div>
          </div>
        ))}
        <div className="flex h-6 w-9 mx-2 rounded-full justify-center items-center">
          <div className="text-black_grey text-base font-semibold">
            {outCount}
          </div>
        </div>
        <div className="flex w-14 h-6 justify-center items-center">
          <div className="text-middle_grey font-bold text-xxs"></div>
        </div>
        <div className="flex w-14 h-6 justify-center items-center">
          <div className="text-middle_grey font-bold text-xxs"></div>
        </div>
      </div>

      {scorecards
        ? scorecards.map((scorecard, scorecardIndex) => {
            return (
              <ScorecardRow
                key={`sc_card_row_${scorecardIndex}`}
                scorecardIndex={scorecardIndex}
                scorecard={scorecard}
                holes={holes}
              />
            );
          })
        : null}
    </div>
  );
};

export default ScorecardAllRounds;

import React from "react";
import useFavoritesStore from "../../hooks/store/useFavoritesStore";
import { ReactComponent as StarIcon } from "./../../assets/icons/icons-star-outline.svg";

const FavButton: React.FC<{ playerId: number; size?: number }> = ({
  playerId,
  size = 32,
}) => {
  const { favs, toggleFav } = useFavoritesStore();

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    return toggleFav(playerId);
  }

  const isFavorite = favs.includes(playerId);
  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <button type="button" className="fav-button" onClick={handleClick}>
      <StarIcon
        style={sizeStyle}
        className={`${isFavorite ? "hover-star-active" : "hover-star"}`}
      />
    </button>
  );
};

export default FavButton;

import useCookieStore from "../../hooks/store/useCookieStore";

const CookieAcccept: React.FC<{}> = () => {
  const { cookieAccept, setCookieAccept } = useCookieStore();

  if (cookieAccept) {
    return null;
  }

  return (
    <div className="bg-white p-4 md:p-8 fixed left-0 right-0 bottom-16 z-50">
      <p className="text-black_grey font-semibold text-base tracking-wider">
        We use cookies and browser storage on this website. These are solely for
        the purpose of providing you the best possible experience and are
        therefore mandatory.
      </p>
      <div className="mt-8">
        <button
          onClick={() => setCookieAccept()}
          className="bg-blue text-white_grey font-semibold px-4 py-2"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieAcccept;

import { useCallback, useEffect, useState, WheelEvent } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import useIsMobile from "../../../hooks/useIsMobile";
import { BASE_TITLE } from "../../../misc/config";
import courseMarkerCoordinates from "../../../misc/courseMarkerCoordinates";
import TopNavigation from "../../layout/TopNavigation";
import PartnerLogos from "../../shared/PartnerLogos";
import TopLeaderboard from "../../shared/TopLeaderboard";

function isVisible(el: any) {
  if (el == null || !el) {
    return false;
  }
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const HoleMarker: React.FC<{
  handleClick: any;
  currentHole: number;
  isMobile: boolean;
  hole: {
    hole: number;
    x: number;
    y: number;
  };
}> = ({ handleClick, currentHole, hole }) => {
  const selected = currentHole === hole.hole;
  return (
    <g
      onClick={handleClick}
      id={`hole-${hole.hole}`}
      className={`group transition-all hover:text-blue cursor-pointer
      ${selected ? "text-blue" : "text-white"}
      `}
    >
      <circle
        cx={hole.x}
        cy={hole.y}
        r={12}
        className="fill-current opacity-90"
      ></circle>
      <circle
        cx={hole.x}
        cy={hole.y}
        r={15}
        fill="none"
        strokeWidth={6}
        stroke="white"
        className={`stroke-current
                ${selected ? "text-blue opacity-25" : "text-white opacity-50"}
              `}
      ></circle>
      <text
        x={hole.x}
        y={hole.y}
        textAnchor="middle"
        className={`stroke-current group-hover:text-white text-xxs
                ${selected ? "text-white" : "text-black_grey"}
              `}
        strokeWidth="1px"
        dominantBaseline="central"
      >
        {String(hole.hole)}
      </text>
    </g>
  );
};

const Map: React.FC<{ sheetFullscreen?: () => void | null }> = ({
  sheetFullscreen,
}) => {
  const [showMap, setShowMap] = useState(false);
  const [svgWidth, setSvgWidth] = useState(0);
  const [xOffset, setXOffset] = useState(0);
  const [mapZoom, setMapZoom] = useState(1);

  const { isMobile } = useIsMobile();
  const history = useHistory();
  const location = useLocation();

  const selected = location.pathname.includes("/hole/")
    ? location.pathname.split("/")[3]
    : null;

  const currentHole = selected ? Number(selected) : 1;
  const currentHoleIndex = selected ? currentHole - 1 : 1;

  const calcWidthAndOffset = useCallback(() => {
    let newSvgWidth = window.innerHeight;
    if (!isMobile) {
      if (window.innerWidth < 1080) {
        newSvgWidth = window.innerHeight - 107;
      }
      if (window.innerWidth < 1440 && window.innerHeight > 1000) {
        newSvgWidth = window.innerWidth - 100;
      }
    } else {
      newSvgWidth = window.innerHeight;
    }

    setSvgWidth(newSvgWidth > 1000 ? 1200 : newSvgWidth);

    let newOffset = 0;
    if (!isMobile) {
      if (
        (window.innerWidth > 1400 && window.innerHeight < 1120) ||
        (window.innerHeight - 680 <= newSvgWidth && window.innerWidth < 1500)
      ) {
        newOffset = (window.innerWidth - 380) / 2 - newSvgWidth / 2;
      } else {
        newOffset = window.innerWidth / 2 - newSvgWidth + 510;
      }
    }
    setXOffset(newOffset);
  }, [isMobile]);

  useEffect(() => {
    function focusHole() {
      calcWidthAndOffset();

      const holeIsVisible = isVisible(
        document.getElementById(`hole-${currentHoleIndex - 1}`),
      );

      var mapWrapper = document.getElementById("course-map-wrapper");
      setTimeout(() => {
        if (mapWrapper) {
          if (
            !holeIsVisible &&
            isMobile &&
            courseMarkerCoordinates[currentHoleIndex]
          ) {
            mapWrapper.scrollTo({
              top: courseMarkerCoordinates[currentHoleIndex].y,
              left: courseMarkerCoordinates[currentHoleIndex].x - 55,
              behavior: showMap ? "smooth" : "auto",
            });
          }
          if (!showMap) {
            setShowMap(true);
          }
        }
      }, 400);
    }
    window.addEventListener("resize", focusHole);
    focusHole();

    return () => window.removeEventListener("resize", focusHole);
  }, [showMap, currentHoleIndex, isMobile, calcWidthAndOffset]);

  function handleHoleClick(holeNumber: number) {
    history.push(`/course/hole/${holeNumber}`);
    if (sheetFullscreen) {
      sheetFullscreen();
    }
  }

  function handleMouseWheel(event: WheelEvent<HTMLDivElement>) {
    const map = document.getElementById("t");
    if (map) {
      const originX = (event.clientX / window.innerWidth) * 100;
      const originY = (event.clientY / (window.innerHeight + 200)) * 100;
      const transformOrigin = `${originX}% ${originY}%`;
      map.style.transformOrigin = transformOrigin;
      // mouse wheel up
      if (event.deltaY < 0) {
        setMapZoom((current) => {
          if (current < 1.8) {
            return current + 0.3;
          } else {
            return current;
          }
        });
      } else {
        // mouse wheel down
        setMapZoom((current) => {
          if (current > 1) {
            return current - 0.3;
          } else {
            return 1;
          }
        });
      }
    }
  }

  const fullPageTitle = `${BASE_TITLE} - Track`;

  return (
    <div
      className="overflow-auto md:overflow-x-hidden md:overflow-y-hidden pb-40 mb:pb-0"
      style={{
        height: "100vh",
      }}
      id="course-map-wrapper"
    >
      <Helmet>
        <title>{fullPageTitle}</title>
        <meta property="og:title" content={fullPageTitle} />
      </Helmet>
      <div
        onWheel={handleMouseWheel}
        className={`h-screen relative sm:pt-12 xs:pt-24 transition-opacity duration-200 ${
          showMap ? "opacity-100" : "opacity-0"
        }`}
        style={{
          top: isMobile ? 0 : window.innerHeight > 900 ? 0 : -10,
          width: svgWidth,
          maxWidth: 1500,
        }}
      >
        <svg
          id="t"
          viewBox={`0 0 ${!isMobile ? "1100 1100" : "900 900"}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            transform: `scale(${mapZoom})`,
            transition: "ease-in-out all",
            maxWidth: "100%",
            overflow: "visible",
          }}
        >
          <image
            x={-850 + xOffset}
            y={-190}
            href="/assets/images/map_23.webp"
            className="pointer-events-none svg-map-image"
          />
          {courseMarkerCoordinates.map((hcoords, index) => {
            const moddedCoords = {
              hole: hcoords.hole,
              x: hcoords.x + xOffset,
              y: hcoords.y,
            };
            return (
              <HoleMarker
                key={`marker_${index}`}
                handleClick={() => handleHoleClick(hcoords.hole)}
                currentHole={Number(selected)}
                isMobile={isMobile}
                hole={moddedCoords}
              />
            );
          })}
        </svg>
      </div>
    </div>
  );
};

const Course: React.FC<{ sheetFullscreen: any }> = ({
  sheetFullscreen,
  children,
}) => {
  return (
    <div>
      <TopLeaderboard />
      <TopNavigation />
      <Map sheetFullscreen={sheetFullscreen || null} />
      {children}
      <div className="flex justify-between md:justify-start flex-1 w-full absolute px-6 items-end bottom-14 md:bottom-0 pointer-events-none">
        <PartnerLogos />
      </div>
    </div>
  );
};

export default Course;

import moment, { Moment } from "moment";
import { PlayerScorecardHole } from "../types/players";
import {
  LIVESTREAM_DATES,
  LIVESTREAM_DATES_FORMAT,
  TOURNAMENT_END_DATE,
  TOURNAMENT_START_DATE,
} from "./config";

export function truncPlayerName(first_name: string, last_name: string) {
  return `${first_name.slice(0, 1)}. ${last_name}`;
}

export function filterPlayedHoles(h: PlayerScorecardHole) {
  return h.holeStrokes !== "0" && h.holeStrokes !== "-";
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: any) {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : 0;
}

const START = TOURNAMENT_START_DATE.clone();
export function calcTournamentRound() {
  if (START.isSame(moment(), "day")) {
    return 1;
  }
  if (START.clone().add(1, "day").isSame(moment(), "day")) {
    return 2;
  }
  if (START.clone().add(2, "day").isSame(moment(), "day")) {
    return 3;
  }
  if (START.clone().add(3, "day").isSame(moment(), "day")) {
    return 4;
  }
  return 4;
}
export const currentTournamentRound = calcTournamentRound();

export function getTournamentRoundByIndex(index: number): Moment {
  const d = index - 1;
  if (index > 0) {
    return moment(TOURNAMENT_START_DATE).clone().add(d, "days");
  }
  return moment(TOURNAMENT_START_DATE);
}

export function getFormattedScore(score: string | number) {
  return `${Number(score) === 0 ? "E" : score}`;
}

export function getCurrentPlayerHole(holesThrough: number, startHole: number) {
  if (holesThrough === 18) {
    return holesThrough;
  }
  // Start 1
  if (startHole === 1) {
    return holesThrough;
  }

  if (startHole !== 1) {
    const startPlayedCombined = startHole + holesThrough;
    if (startPlayedCombined > 18) {
      return startPlayedCombined - 18;
    }
    return startPlayedCombined;
  }
}

const YARD_METER = 1.094;
export function yardsToMeters(yards: number) {
  if (!yards) return 0;
  return Math.floor(yards / YARD_METER).toFixed(0);
}

export function isTournamentOver() {
  return (
    moment(moment()).diff(
      moment(LIVESTREAM_DATES[4].end, LIVESTREAM_DATES_FORMAT),
      "minutes",
    ) > 0
  );
}

export function ordinalSuffixOf(i: number) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}

export function isTournamentDay() {
  return moment().isBetween(
    TOURNAMENT_START_DATE,
    TOURNAMENT_END_DATE.endOf("day"),
  );
}

export function isAfterTournamentStart() {
  return moment().isAfter(TOURNAMENT_START_DATE);
}

export function tournamentDay() {
  let dateClone = TOURNAMENT_START_DATE.clone();
  if (dateClone.isAfter(moment())) {
    return 1;
  }
  if (moment().isAfter(TOURNAMENT_END_DATE)) {
    return 4;
  }
  const days = [
    dateClone.clone().format("DD"),
    dateClone.clone().add(1, "days").format("DD"),
    dateClone.clone().add(2, "days").format("DD"),
    dateClone.clone().add(3, "days").format("DD"),
  ];
  return Number(days.find((d) => d === moment().format("DD"))) || 4;
}

export function tournamentDayIndex(): number {
  let dateClone = TOURNAMENT_START_DATE.clone();
  if (dateClone.isAfter(moment())) {
    return 0;
  }
  if (moment().isAfter(TOURNAMENT_END_DATE)) {
    return 3;
  }
  const days = [
    dateClone.clone().format("DD"),
    dateClone.clone().add(1, "days").format("DD"),
    dateClone.clone().add(2, "days").format("DD"),
    dateClone.clone().add(3, "days").format("DD"),
  ];
  const d = days.findIndex((d) => d === moment().format("DD"));
  if (d == null) {
    return 3;
  }
  return d;
}

export function livestreamActive() {
  if (!isTournamentDay()) return false;

  const selectedDay = tournamentDayIndex() + 1;
  const streamDay = LIVESTREAM_DATES[selectedDay];

  if (
    moment().isBetween(
      moment(streamDay.start, "DD.MM.YYYY HH:mm"),
      moment(streamDay.end, "DD.MM.YYYY HH:mm"),
    )
  ) {
    return true;
  }

  return false;
}

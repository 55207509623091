import { useEffect } from "react";
import { ReactComponent as Imgarena } from "./../../assets/icons/logo-imgarena.svg";

const PartnerLogos: React.FC<{}> = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.getElementById("imgarena-logo")?.classList.add("hidden");
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <div className="h-16 items-center justify-center flex">
        <img
          src="/assets/images/icons-ntt-big-2.png"
          style={{ width: 135 }}
          alt="NTT Data"
          title="NTT Data"
        />
      </div>
      <div
        id="imgarena-logo"
        className="pr-4 h-16 flex items-center justify-center md:ml-24"
      >
        <div>
          <div className="text-grey4 text-xxs font-bold">data source</div>
          <Imgarena className="w-20" />
        </div>
      </div>
    </>
  );
};

export default PartnerLogos;

import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import api, { leaderboard_url } from "../../misc/api";
import {
  LeaderboardItem,
  LeaderboardItemSchema,
} from "../../types/leaderboard";
import baseOptions from "./baseOptions";

async function fetchLeaderboard() {
  try {
    const { data }: AxiosResponse<LeaderboardItem[]> =
      await api.get(leaderboard_url);
    const safeParsedData = LeaderboardItemSchema.array()
      .parse(data)
      .filter((p) => p.isPlayoffRound === false);
    return safeParsedData;
  } catch (e) {
    throw new Error(e);
  }
}

function useLeaderboard() {
  return useQuery("leaderboard", () => fetchLeaderboard(), {
    ...baseOptions,
    retry: 3,
  });
}

export default useLeaderboard;

import React from "react";
import { useHistory } from "react-router-dom";
import i18n from "../../../misc/i18n";
import { HolePairing } from "../../../types/hole";
import FlightRow from "./FlightRow";

type FlightProps = {
  flight: HolePairing[];
  flightNumber: number;
  holeNumber: number;
};

const Flight: React.FC<FlightProps> = ({
  flight,
  flightNumber,
  holeNumber,
}) => {
  const history = useHistory();

  function handleRowClick(id: string) {
    history.push(`/players/${id}`);
  }

  return (
    <div className="pt-10">
      <div className="pb-9">
        <h3 className="text-xs font-bold text-darkgrey uppercase">
          {i18n.hole.flight} {flightNumber}
        </h3>
      </div>
      <table className="w-full">
        <tbody>
          {flight.map((f, i) => {
            return (
              <FlightRow
                key={f.playerId}
                index={i}
                currentHole={holeNumber}
                player={f}
                handleRowClick={() => handleRowClick(String(f.playerId))}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Flight;

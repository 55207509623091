import React, { useCallback } from "react";
import useCourse from "../../../hooks/queries/useCourse";
import useScorecardValues from "../../../hooks/useScorecardValues";
import { CourseHole } from "../../../types/course";
import { PlayerScorecard } from "../../../types/players";
import ScoreBox from "./ScoreBox";

type ScorecardProps = {
  scorecard: PlayerScorecard | null;
  hideHeader?: boolean;
};
type ScorecardHoleProps = {
  hole: CourseHole;
  score: number;
};

const ScorecardHole: React.FC<ScorecardHoleProps> = ({ hole, score }) => {
  return (
    <div className="flex flex-col">
      <div>
        <div className="flex justify-center items-center w-5 h-5 rounded-full bg-white_grey">
          <div className="text-xxs text-black_grey font-bold">
            {hole.holeNo}
          </div>
        </div>
      </div>
      <div className="text-darkgrey text-base font-semibold text-black_grey text-center mt-2">
        {hole.par}
      </div>
      <div className={`text-base text-center mt-2`}>
        <ScoreBox score={score} hole={hole} />
      </div>
    </div>
  );
};

const HoleSum: React.FC<{ title: string; count: number; score: number }> = ({
  title,
  count,
  score,
}) => {
  return (
    <div className="flex flex-col -mt-1">
      <div>
        <div className="flex justify-center items-center w-10 h-6 rounded-full bg-white_grey text-center">
          <div className="text-xxs text-black_grey font-normal">{title}</div>
        </div>
        <div className="text-black_grey font-semibold text-base text-center pt-2">
          {count}
        </div>
        <div className="text-blue text-center pt-2 text-base font-semibold">
          {score === 0 ? "-" : score}
        </div>
      </div>
    </div>
  );
};

const Scorecard: React.FC<ScorecardProps> = ({
  scorecard,
  //hideHeader = false,
}) => {
  const { data: holes } = useCourse();

  const {
    inCount,
    inScore,
    outCount,
    outScore,
    frontNineFinished,
    backNineFinished,
  } = useScorecardValues({ holes, scorecard });

  const findCurrentScoreHole = useCallback(
    (hole: CourseHole) => {
      return scorecard
        ? scorecard.find(
            (scoreHole) =>
              Number(scoreHole.hole) === Number(hole.holeNo) &&
              scoreHole.holeStrokes !== "0" &&
              scoreHole.holeStrokes !== "-",
          )
        : null;
    },
    [scorecard],
  );

  if (!holes) return null;
  return (
    <div className="flex flex-row">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="flex flex-1 flex-row justify-between pb-8">
          {holes.slice(0, 9).map((h, i) => {
            const currentHole = findCurrentScoreHole(h);
            const score = currentHole ? currentHole.holeStrokes : 0;
            return (
              <ScorecardHole
                key={`front_${i}`}
                hole={h}
                score={Number(score)}
              />
            );
          })}
          <HoleSum
            title="In"
            count={inCount}
            score={frontNineFinished && inScore !== null ? inScore : 0}
          />
          <div className="w-10"></div>
        </div>
        <div className="flex flex-1 flex-row justify-between">
          {holes.slice(9, 18).map((h, i) => {
            const currentHole = findCurrentScoreHole(h);

            const score = currentHole ? currentHole.holeStrokes : 0;
            return (
              <ScorecardHole key={`back_${i}`} hole={h} score={Number(score)} />
            );
          })}
          <HoleSum
            title="Out"
            count={outCount}
            score={backNineFinished && outScore !== null ? outScore : 0}
          />
          <HoleSum
            title="Sco"
            count={holes.reduce((sum, h) => sum + h.par, 0)}
            score={(outScore || 0) + (inScore || 0)}
          />
        </div>
      </div>
    </div>
  );
};

export default Scorecard;

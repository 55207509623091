import { useEffect, useRef, useState } from "react";
import useIntersectionObserver from "../../../hooks/useIntersection";

const PlayerStatCircle: React.FC<{
  statValue: number;
  statExtension: string;
  statTitle: string;
  isMobile: boolean;
}> = ({ statValue, statExtension, statTitle, isMobile }) => {
  const ref = useRef<HTMLDivElement | any>();
  const animateRef = useRef<SVGAnimateElement | any>();
  const intersectionEntry = useIntersectionObserver(ref, {
    threshold: 0.5,
    freezeOnceVisible: true,
  });
  const inViewport = intersectionEntry && intersectionEntry.isIntersecting;

  const [done, setDone] = useState(false);

  const size = isMobile ? 150 : 230;
  const circleSize = size / 2;

  const dashArrayVal = 2 * Math.PI * (size / 2);
  const animateVal = dashArrayVal * (-1 - statValue / 100);

  useEffect(() => {
    let timeout: null | any = null;

    if (inViewport && animateRef.current && !done) {
      timeout = setTimeout(() => {
        animateRef.current.beginElement();
        setDone(true);
      }, 200);
    }

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [inViewport, done, statValue]);

  useEffect(() => {
    setDone(false);
  }, [statValue]);

  return (
    <div ref={ref}>
      <svg width={size} height={size}>
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="70%" y2="70%">
            <stop
              offset="0%"
              style={{
                stopOpacity: 1,
                stopColor: "#78A9ED",
              }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: "#054494",
                stopOpacity: 1,
              }}
            />
          </linearGradient>
        </defs>
        <circle
          cx={circleSize}
          cy={circleSize}
          r={circleSize - 5}
          className="stroke-current text-dark_grey"
          strokeWidth="9"
          fill="none"
        />
        <circle
          cx={circleSize}
          cy={circleSize}
          r={circleSize - 5}
          strokeLinecap="round"
          stroke="url(#grad1)"
          transform={`rotate(-90, ${circleSize}, ${circleSize})`}
          strokeWidth="9"
          strokeDasharray={dashArrayVal}
          strokeDashoffset={dashArrayVal * -1}
          fill="none"
        >
          <animate
            ref={animateRef}
            attributeName="stroke-dashoffset"
            values={`${-dashArrayVal};${animateVal}`}
            dur="0.6s"
            begin="indefinite"
            fill="freeze"
            repeatCount="1"
          />
        </circle>
        <foreignObject x="10" y="24%" height="30" width={size - 20}>
          <p className="uppercase text-center font-normal text-tiny md:text-xxs text-white break-words h-full flex justify-center items-center">
            <span>{statTitle}</span>
          </p>
        </foreignObject>
        <text x="53%" y="70%" textAnchor="middle">
          <tspan className="font-light text-3xl md:text-5xl text-white fill-current">
            {statValue}
          </tspan>
          <tspan className="font-light text-base md:text-lg text-white fill-current">
            {statExtension}
          </tspan>
        </text>
      </svg>
    </div>
  );
};

export default PlayerStatCircle;

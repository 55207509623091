import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import useLeaderboard from "../../hooks/queries/useLeaderboard";
import { LeaderboardItem } from "../../types/leaderboard";
import Avatar from "./Avatar";

import { ReactComponent as SearchIcon } from "./../../assets/icons/icons-search.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/icons-close.svg";
import FavButton from "./FavButton";
import useIsMobile from "../../hooks/useIsMobile";
import { mostSearchedPlayerIds } from "../../misc/config";

const LeaderboardSearch: React.FC<{ openMode?: boolean }> = ({
  openMode = false,
}) => {
  const leaderboard = useLeaderboard();

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<LeaderboardItem[]>([]);
  const history = useHistory();
  const { isMobile } = useIsMobile();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const mostSearchedPlayers = useMemo(
    () =>
      leaderboard.data?.filter((player) =>
        mostSearchedPlayerIds.includes(Number(player.playerId)),
      ),
    [leaderboard.data],
  );

  const setInitialResults = useCallback(() => {
    if (leaderboard && leaderboard.data) {
      if (mostSearchedPlayers && mostSearchedPlayers.length > 0) {
        setResults(mostSearchedPlayers);
      } else {
        setResults(leaderboard.data?.slice(0, 7));
      }
    }
  }, [leaderboard, mostSearchedPlayers]);

  useEffect(() => {
    if (searchTerm === "" && results.length === 0) {
      setInitialResults();
    }
  }, [leaderboard, searchTerm, setSearchTerm, results, setInitialResults]);

  function handleSearchTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    if (newValue.length > 2 && leaderboard && leaderboard.data) {
      const filtered = leaderboard.data?.filter((item) => {
        return (
          item.firstName.toLowerCase().includes(newValue.toLowerCase()) ||
          item.lastName.toLowerCase().includes(newValue.toLowerCase())
        );
      });
      setResults(filtered);
    } else if (newValue === "") {
      setInitialResults();
    }
  }

  function handleClick() {
    setOpen(!open);
    if (!open && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }

  function close() {
    setOpen(false);
    setResults([]);
    setSearchTerm("");
  }

  function handleSelect(p: LeaderboardItem) {
    close();
    history.push(`/players/${p.playerId}`);
  }

  return (
    <>
      <div
        className={`sticky left-0 flex flex-1 group items-center
        ${
          openMode
            ? "justify-start search-blur hover:text-white focus-within:text-white text-middle_grey"
            : "justify-center bg-white z-20 border-r-0.5 border-light_grey hover:text-dark_grey focus-within:text-dark_grey text-middle_grey"
        }
        `}
      >
        <button
          onClick={handleClick}
          type="button"
          className="xs:w-16 sm:w-16 md:w-24 h-top-leaderboard-item justify-center items-center flex"
        >
          <SearchIcon title="Suche" />
        </button>
        <div
          className={`${
            open || openMode ? "w-screen pr-4" : "w-0 delay-200"
          } flex flex-row transition-all h-top-leaderboard-item justify-between items-center overflow-hidden ease-in-out
            ${openMode ? "flex-1" : ""}
            ${open && !openMode ? "md:w-80" : ""}
          `}
          style={
            isMobile && open
              ? {
                  width: "calc(100vw - 60px)",
                }
              : {}
          }
        >
          <div className="text-2xl flex-1">
            <input
              ref={inputRef}
              value={searchTerm}
              onFocus={() => setOpen(true)}
              onBlur={() => setTimeout(() => setOpen(false), 200)}
              onChange={handleSearchTermChange}
              className="w-full bg-transparent outline-none"
              type="search"
              placeholder="Search player"
            />
          </div>
          <div>
            {!openMode ? (
              <button
                type="button"
                className="flex flex-1 w-9 group"
                onClick={close}
              >
                <CloseIcon />
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={`${open ? "h-80 delay-200 z-50" : "h-0"}
         ${openMode ? "top-18" : "top-16"}
         w-screen md:w-search-results transition-all bg-white absolute z-10 left-0 px-5 overflow-hidden`}
      >
        {open ? (
          <>
            <h3 className="text-xs p-4 font-bold uppercase tracking-wider">
              Most popular
            </h3>
            <div className="overflow-y-auto max-h-64">
              {results.length === 0 && searchTerm.length > 2 ? (
                <h3 className="px-6 font-bold text-middle_grey text-xxs uppercase tracking-wider">
                  no matches found
                </h3>
              ) : null}
              {results.map((p) => {
                return (
                  <div
                    key={p.playerId}
                    className="cursor-pointer flex flex-row items-center justify-start p-3 border-b-0.5 border-light_grey hover:bg-white_grey"
                  >
                    <div
                      className="flex flex-1 items-center"
                      onClick={() => handleSelect(p)}
                    >
                      <div className="px-2">
                        <Avatar
                          playerName={p.firstName + " " + p.lastName}
                          playerId={String(p.playerId)}
                        />
                      </div>
                      <div className="text-sm font-bold text-black_grey flex flex-1">
                        {p.firstName} {p.lastName}
                      </div>
                    </div>
                    <div>
                      <FavButton playerId={p.playerId} size={24} />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default LeaderboardSearch;

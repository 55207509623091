import i18n from "../../misc/i18n";

type ErrorProps = {
  message?: string;
};

const Error: React.FC<ErrorProps> = ({
  message = i18n.messages.networkError,
}) => {
  return (
    <div className="flex flex-1 w-full h-full justify-center">
      <div className="py-11">
        <h3 className="text-tiny uppercase text-darkgrey">{message}</h3>
      </div>
    </div>
  );
};

export default Error;

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useCountdown from "../../hooks/useCountdown";
import {
  LIVESTREAM_DATES_FORMAT,
  TOURNAMENT_START_DATE,
} from "../../misc/config";
import { isAfterTournamentStart } from "../../misc/helpers";
import i18n from "../../misc/i18n";

function formatNumber(num: number) {
  if (num < 0) return "00";
  if (num < 10) {
    return `0${num}`;
  }
  return num;
}

type CountdownProps = { small?: boolean; isMobile?: boolean };

const CountdownTournamentDay: React.FC<CountdownProps> = ({
  small,
  isMobile,
}) => {
  const history = useHistory();
  const startDate = TOURNAMENT_START_DATE;

  const { timeLeft } = useCountdown({
    finishDate: startDate.format(LIVESTREAM_DATES_FORMAT),
  });

  useEffect(() => {
    if (isAfterTournamentStart()) {
      history.replace("/leaderboard");
    }
  }, [timeLeft, history]);

  return (
    <div
      className={`text-white`}
      style={
        small && !isMobile
          ? {
              transform: `scale(${window.innerHeight > 900 ? 0.3 : 0.2})`,
            }
          : {}
      }
    >
      <div className="flex flex-1 flex-row justify-center">
        {timeLeft.days !== 0 ? (
          <>
            <div className="md:w-28 text-center flex flex-col items-center">
              <div className="text-darkgrey text-4xl md:text-8xl md:mb-8">
                {formatNumber(timeLeft.days)}
              </div>
              <div>
                <span className="text-xs md:text-xl text-brown_grey uppercase">
                  {i18n.countdown.days}
                </span>
              </div>
            </div>
            <div>
              <span className="text-darkgrey text-4xl md:text-8xl md:px-3 px-2">
                :
              </span>
            </div>
          </>
        ) : null}
        <div className="md:w-28 text-center flex flex-col items-center">
          <div className="text-darkgrey text-4xl md:text-8xl md:mb-8">
            {formatNumber(timeLeft.hours)}
          </div>
          <div>
            <span className="text-xs md:text-xl text-brown_grey uppercase">
              {i18n.countdown.hours}
            </span>
          </div>
        </div>

        <div>
          <span className="text-darkgrey text-4xl md:text-8xl md:px-3 px-2">
            :
          </span>
        </div>

        <div className="md:w-28 text-center flex flex-col items-center">
          <div className="text-darkgrey text-4xl md:text-8xl md:mb-8 ">
            {formatNumber(timeLeft.minutes)}
          </div>
          <div>
            <span className="text-xs md:text-xl text-brown_grey uppercase">
              {i18n.countdown.minutes}
            </span>
          </div>
        </div>

        <div>
          <span className="text-darkgrey text-4xl md:text-8xl md:px-3 px-2">
            :
          </span>
        </div>
        <div className="md:w-28 text-center flex flex-col items-center">
          <div className="text-darkgrey text-4xl md:text-8xl md:mb-8">
            {formatNumber(timeLeft.seconds)}
          </div>
          <div>
            <span className="text-xs md:text-xl text-brown_grey uppercase">
              {i18n.countdown.seconds}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountdownTournamentDay;

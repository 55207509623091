import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as ExternIcon } from "./../../assets/icons/icons-extern.svg";
import { ReactComponent as MenuIcon } from "./../../assets/icons/icons-menu.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/icons-close.svg";
import { ReactComponent as ArrowLeft } from "./../../assets/icons/icons-arrow-left.svg";
import "./navigation.css";
import { livestreamActive } from "../../misc/helpers";
import useGeoLivestream from "../../hooks/useGeoLivestream";
import { IMPRESS_LINK, PRIVACY_POLICY_LINK } from "../../misc/config";

const links = {
  podcast: {
    title: "Tournament Site",
    link: "https://www.bmw-golfsport.com/en/topics/turniere/bmw-international-open/tournament.html",
  },
  teeTimes: {
    title: "Tee Times",
    link: "/assets/R4_DrawRnd4_20240706_144156.pdf",
  },
};

const NavItem: React.FC<{ to: string }> = ({ to, children }) => (
  <NavLink
    to={to}
    className="font-bold text-white text-xs lg:text-base tracking-wide flex flex-row h-full py-7 items-center justify-center border-transparent border-b-4 border-bluehover border-opacity-0 hover:border-opacity-100 px-5"
    activeClassName="border-b-4 border-bluehover border-opacity-100"
  >
    {children}
  </NavLink>
);

const NavItemExternal: React.FC<{ href: string }> = ({ href, children }) => (
  <a
    className="font-bold text-white text-xs lg:text-base tracking-wide"
    target="_blank"
    rel="noopener noreferrer"
    href={href}
  >
    <div className="flex flex-row py-7 items-center justify-center border-transparent border-b-4 border-bluehover border-opacity-0 hover:border-opacity-100 px-5">
      {children}
    </div>
  </a>
);

const TopNavigation: React.FC<{}> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const { livestreamAvailable } = useGeoLivestream();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <nav
      className={`nav h-24 md:h-28 overflow-y-hidden absolute left-0 right-0 ${
        isMenuOpen ? "top-0 h-screen z-50" : "top-18 z-10"
      }`}
    >
      <div className="px-6 md:px-24 bg-gradient-to-b from-gray-900 to-transparent">
        <div className="flex flex-row justify-between md:hidden">
          <div>
            <button
              type="button"
              onClick={() => setIsMenuOpen(true)}
              className="mt-4"
            >
              <MenuIcon />
            </button>
          </div>
          <div className="flex flex-1 flex-row justify-center items-center">
            {livestreamActive() ? (
              <div className="flex flex-row items-center justify-center bg-blue rounded-sm p-1 max-h-5 ml-2">
                <div className="w-2 h-2 bg-white rounded-full mr-2"></div>
                <div className="text-white text-tiny tracking-wider font-bold">
                  LIVE
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-4">
            <img
              alt="BMW Logo"
              src="https://www.bmw-golfsport.com/etc.clientlibs/ds2-webcomponents/clientlibs/clientlib/resources/img/BMW_White_Logo.svg"
            />
          </div>
        </div>
        <div
          className={`${
            !isMenuOpen ? "-translate-x-full opacity-0" : "opacity-100"
          } transform transition-all w-screen h-screen bg-white absolute top-0 left-0`}
        >
          {isMenuOpen ? (
            <>
              <div className="flex flex-1 flex-row justify-end">
                <button
                  type="button"
                  onClick={() => setIsMenuOpen(false)}
                  className="h-10 w-10 mt-4 mr-6"
                >
                  <CloseIcon className="text-middle_grey h-12 w-12" />
                </button>
              </div>
              <div className="flex flex-1 flex-col px-5 pt-8">
                <Link
                  className="flex w-full py-7 border-b-0.5 border-light_grey items-center justify-between"
                  to="/leaderboard"
                >
                  <div className="font-bold text-base text-black_grey tracking-wider">
                    Leaderboard
                  </div>
                  <div className="transform rotate-180">
                    <ArrowLeft className="text-middle_grey" />
                  </div>
                </Link>
                <Link
                  className="flex w-full py-7 border-b-0.5 border-light_grey items-center justify-between"
                  to="/course"
                >
                  <div className="font-bold text-base text-black_grey tracking-wider">
                    Golf Course
                  </div>
                  <div className="transform rotate-180">
                    <ArrowLeft className="text-middle_grey" />
                  </div>
                </Link>
                {livestreamAvailable ? (
                  <Link
                    className="flex w-full py-7 border-b-0.5 border-light_grey items-center justify-between"
                    to="/livestream"
                  >
                    <div className="font-bold text-base text-black_grey tracking-wider">
                      Livestream
                    </div>
                    {livestreamActive() ? (
                      <div className="flex flex-row items-center justify-center bg-blue rounded-sm p-1 max-h-5 ml-2">
                        <div className="w-2 h-2 bg-white rounded-full mr-2"></div>
                        <div className="text-white text-tiny tracking-wider font-bold">
                          LIVE
                        </div>
                      </div>
                    ) : null}
                    <div className="transform rotate-180">
                      <ArrowLeft className="text-middle_grey" />
                    </div>
                  </Link>
                ) : null}

                <a
                  className="flex w-full py-7 border-b-0.5 border-light_grey"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={links.podcast.link}
                >
                  <div className="font-bold text-base text-black_grey tracking-wider">
                    {links.podcast.title}
                  </div>
                </a>
                <a
                  className="flex w-full py-7 border-b-0.5 border-light_grey"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={links.teeTimes.link}
                >
                  <div className="font-bold text-base text-black_grey tracking-wider">
                    {links.teeTimes.title}
                  </div>
                </a>
              </div>
              <div className="flex flex-1 flex-col text-middle_grey font-semibold text-xs px-5 mt-8">
                <div className="py-4">
                  <a
                    href={IMPRESS_LINK}
                    title="Impress"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Imprint
                  </a>
                </div>
                <div className="py-4">
                  <a
                    href={PRIVACY_POLICY_LINK}
                    title="Privacy Policy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="flex-row hidden md:flex">
          <div className="flex flex-1 flex-row justify-between border-opacity-50 border-b-0.5 border-white">
            <div className="internal-row justify-start flex-row flex">
              <NavItem to="/leaderboard">Leaderboard</NavItem>
              <NavItem to="/course">Golf Course</NavItem>
              {livestreamAvailable ? (
                <NavItem to="/livestream">
                  Livestream
                  {livestreamActive() ? (
                    <div className="flex flex-row items-center justify-center bg-blue rounded-sm p-1 max-h-5 ml-2">
                      <div className="w-2 h-2 bg-white rounded-full mr-2"></div>
                      <div className="text-white text-tiny tracking-wider font-bold">
                        LIVE
                      </div>
                    </div>
                  ) : null}
                </NavItem>
              ) : null}
            </div>
            <div className="external-row justify-end flex-row flex">
              <NavItemExternal href={links.podcast.link}>
                {links.podcast.title}
                <ExternIcon />
              </NavItemExternal>
              <NavItemExternal href={links.teeTimes.link}>
                {links.teeTimes.title}
                <ExternIcon />
              </NavItemExternal>
            </div>
          </div>
          <div className="flex flex-shrink-0 justify-center align-middle w-16 items-center ml-12">
            <img
              alt="BMW Logo"
              src="https://www.bmw-golfsport.com/etc.clientlibs/ds2-webcomponents/clientlibs/clientlib/resources/img/BMW_White_Logo.svg"
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopNavigation;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useIsMobile() {
  const [mobile, setMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 820 || window.innerHeight < 400);
      setWidth(window.innerWidth);
    };
    let timeoutId: any = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 200 milliseconds
      timeoutId = setTimeout(() => handleResize(), 200);
    };
    handleResize();
    // if (location.pathname.includes("/livestream")) {
    //   window.removeEventListener("resize", handleResize);
    // } else {
    window.addEventListener("resize", resizeListener);
    // }
    return () => {
      window.removeEventListener("resize", resizeListener);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [location]);

  return {
    isMobile: mobile,
    innerWidth: width,
  };
}

import { useMemo } from "react";
import { filterPlayedHoles } from "../misc/helpers";
import { CourseHole } from "../types/course";
import { PlayerScorecard } from "../types/players";

export default function useScorecardValues({
  scorecard,
  holes,
}: {
  scorecard: PlayerScorecard | null;
  holes: CourseHole[] | undefined;
}) {
  const getHalfwayCounts = useMemo(
    () =>
      <T extends { holeNo?: number; hole?: number }>(
        data: Array<T>,
        reduceKey: keyof T,
        front: boolean,
      ) => {
        if (!data) return 0;
        return data
          .filter((s) => {
            let holeNumber = s.holeNo ?? s.hole;
            return front ? holeNumber! < 10 : holeNumber! > 9;
          })
          .map((h) => h[reduceKey])
          .reduce((sum, val) => sum + Number(val), 0);
      },
    [],
  );

  // get par and strokes for FRONT nine
  const inCount = holes
    ? getHalfwayCounts<{ holeNo: number; par: number }>(holes, "par", true)
    : 0;
  const inScore =
    holes && scorecard
      ? getHalfwayCounts<{
          hole: number;
          holePar: number;
          holeStrokes: string;
        }>(scorecard, "holeStrokes", true)
      : 0;
  // get par and strokes for BACK nine
  const outCount = holes
    ? getHalfwayCounts<{ holeNo: number; par: number }>(holes, "par", false)
    : 0;
  const outScore = scorecard
    ? getHalfwayCounts<{
        hole: number;
        holePar: number;
        holeStrokes: string;
      }>(scorecard, "holeStrokes", false)
    : 0;

  const frontNineFinished = scorecard
    ? scorecard.find((s) => s.hole === 9)?.holeStrokes !== "0" || false
    : false;
  const backNineFinished = scorecard
    ? scorecard.find((s) => s.hole === 18)?.holeStrokes !== "0" || false
    : false;

  const playedHoles = scorecard ? scorecard.filter(filterPlayedHoles) : [];
  const playedHolesPar = playedHoles.reduce(
    (sum, val) => sum + Number(val.holePar),
    0,
  );
  const toParScore = scorecard
    ? scorecard.reduce((sum, val) => sum + Number(val.holeStrokes), 0) -
      playedHolesPar
    : 0;

  return {
    inCount,
    inScore: isNaN(inScore) ? null : inScore,
    outCount,
    outScore: isNaN(outScore) ? null : outScore,
    frontNineFinished,
    backNineFinished,
    toParScore: isNaN(toParScore) ? null : toParScore,
  };
}
